@use "sass:math";

@import "../../global";

$detail-panel-max-width: 920rem;

.card {
  background: theme-color(detail-background);
  border-radius: $sidebar-border-radius;
  border: 1rem solid theme-color(border);
  padding-bottom: $floating-button-size-rem + $floating-button-offset;
  position: relative;
  overflow-y: auto;
  height: 100%;
}

.group {
  margin: 0 auto;
  max-width: $detail-panel-max-width;
  position: relative;
  width: calc(100% - #{$panel-padding * 4});

  > :last-child {
    margin-bottom: 0;
  }
}

.scrollable {
  display: flex;
  justify-content: center;
  height: 100%;
  overflow-y: auto;
}

.hero {
  background: theme-color(600);
  border-bottom: 1rem solid theme-color(border);
  display: flex;
  flex-direction: column;
  // 1rem is the width of the border on the parent which makes the radii line up
  border-radius: $sidebar-border-radius - 1rem $sidebar-border-radius - 1rem 0 0;
  padding-top: $panel-padding-top - $sidebar-margin +
    ($button-size-regular * 1rem);
  padding-bottom: $panel-padding;
  min-height: ($panel-padding-top - $sidebar-margin) * 2 +
    ($button-size-regular * 1rem);
  justify-content: flex-end;
  position: relative;

  &.isReversed {
    @include category-opacity-var-override;

    background: theme-color(reversed-background);
    color: theme-color(reversed-text);
  }

  &.hasCategory {
    background: category-color(default);
    border-color: category-color(dark);
    color: category-color(text);
  }

  &.hasBackground {
    background: theme-color(reversed-background);
    border-color: theme-color(border);
    text-shadow: theme-color(reversed-background, 0.5) 0 0 4rem;
    min-height: 280rem;
  }
}

.heroBackgroundImage {
  background: center / cover no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &::before {
    background: linear-gradient(
      theme-color(reversed-background, 0.2),
      theme-color(reversed-background, 0.8) 60%
    );
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.heroHeading {
  display: flex;
  margin: $panel-padding 0 0;
  word-break: break-word;

  svg {
    flex-shrink: 0;
    margin-right: $panel-padding;
    height: 0.667em;
    width: 0.667em;
  }

  path {
    fill: currentColor;
  }
}

.header {
  background: theme-color(600);
  border-bottom: 1rem solid theme-color(border);
  padding: $panel-padding 0;
  margin-bottom: $panel-padding * 2;
}

.title,
.description {
  display: block;
  word-break: break-word;
  text-shadow: inherit;
}

.title {
  $title-size-smallest: 18rem;
  $title-size-largest: $heading-level-3-font-size;
  $title-size-modifier: $title-size-largest - $title-size-smallest;
  // This maths ensures that the size is only changed when the length is half of the threshold
  $title-size-computed: calc(
    #{$title-size-largest + $title-size-modifier} - (#{$title-size-modifier * 2} *
          var(--title-length-limit))
  );

  &[style*="--title-length-limit"] {
    // If clamp isn't supported set the max size to be half way between the two (the support
    // is wide so this won't be used by many users)
    font-size: ($title-size-smallest + $title-size-largest) * 0.5;
    line-height: 1.3;

    @supports (font-size: clamp(1rem, 1rem, 1rem)) {
      font-size: clamp(
        $title-size-smallest,
        $title-size-computed,
        $title-size-largest
      );
    }
  }

  .label + & {
    margin-top: 0;
  }
}

.description {
  font-size: 16rem;
  font-weight: $fw-regular;
  line-height: 24rem;
  margin-bottom: 24rem;
  white-space: pre-line;
}

.buttons {
  position: absolute;
  color: currentColor;
  justify-content: flex-end;
  display: flex;
  top: $panel-padding-top - $sidebar-margin;
  right: $panel-padding;
  left: $panel-padding;
  pointer-events: none;

  // This can currently be a button, an a or a div
  > * {
    margin-left: 10rem;
    flex-shrink: 0;
    pointer-events: auto;
  }
}

.fields {
  margin-top: $panel-padding-top;
}

.form {
  --input-border-color-focus: #{category-color(default, 1, var(--color-accent))};

  padding-top: $panel-padding-top + ($button-size-regular * 1rem);
  height: 100%;

  .group {
    margin: 0;
  }
}

.nameInput {
  input {
    padding-right: calc(
      #{$character-counter-size} + var(--input-padding, 8rem)
    );
  }

  .counter {
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: math.div($character-counter-size, -2);
  }
}

.completedHidden {
  height: 0;
  overflow: hidden;
  visibility: hidden;
}
