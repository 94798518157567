@import "../../../global";

// This padding is required to ensure that there is sufficient space to fit the focus ring
// within the box of the .title (overflow: hidden;) is required to ensure the truncation
// works correctly.
$title-padding: 4rem;
$title-left-offset: 0rem;
$title-left-padding: 16rem;
$title-label-height: 15rem;

// Header dropdown icon button style
$button-height: 32;
$button-height-rem: $button-height * 1rem;
$button-height-var: var(--button-min-size, #{$button-height});
$button-height-hover-increase: 4;

.hasCategory {
  @include category-opacity-var-override;

  background: category-color(default);
  border-color: category-color(dark);
  color: category-color(text);
}

.dropdown {
  display: flex;
  align-items: flex-start;
  // Ensures that if there is no button visible, the title doesn't jump around
  min-height: $button-size-regular * 1rem;
}

.dropdownWrapper {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  flex: 1;
  margin-bottom: $title-padding * -1;
  margin-top: $title-label-height * -1 - $title-padding;
  margin-left: ($title-padding + $title-left-offset) * -1;
  word-break: break-word;
  position: relative;
  padding: $title-padding;
  padding-bottom: 0;
  padding-left: $title-left-padding;
  overflow: hidden;

  @include focus-ring(
    $selector: "&::after",
    $bottom: 0,
    $left: 0,
    $top: 0,
    $right: 0,
    $border-radius: 4rem
  );

  .headerThisWeek & {
    &::after {
      top: $title-label-height !important;
    }
  }

  &:hover {
    .dropdownToggleIcon {
      color: var(--button-color-hover);
      &::after {
        background-color: var(--button-background-hover);
        border-color: var(--button-border-hover);
        opacity: var(--button-after-opacity-hover);
        transform: scale(var(--button-after-scale-hover));
      }
    }
  }
}

.dropdownToggleIcon {
  --button-border: currentColor;
  --button-after-opacity: var(--opacity-ui-mid);
  --button-color: currentColor;

  --button-border-hover: currentColor;
  --button-background-hover: currentColor;
  --button-after-opacity-hover: var(--opacity-ui-mid);

  display: flex;
  flex-direction: column;
  flex: 1;
  position: absolute;
  bottom: 2rem; // Vertically centers the dropdown icon arrows against the first line of the category name
  left: $title-padding; // Offsets the arrow slightly to align the icon itself with the bounds
  padding: 10rem 4rem;
  vertical-align: top;
  min-width: 0;
  box-sizing: border-box;
  border-radius: 100rem;
  align-items: center;
  color: var(--button-color);

  &::after {
    box-sizing: border-box;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background: var(--button-background);
    border: 1rem solid var(--button-border);
    transition: $transition;
    transition-property: background, border, opacity, transform;
    opacity: var(--button-after-opacity);
  }
}

.dropdownArrow {
  transform: rotate(90deg);
  opacity: var(--opacity-text-mid);
  transition: opacity $transition;

  path {
    fill: currentColor;
  }

  .title:hover & {
    opacity: 1;
  }
}

.dropdownLabel {
  @include line-clamp(1);

  font-family: $ff-body;
  line-height: $title-label-height;
  margin: -1rem 0 1rem; // Offsets the label from the text slightly
  opacity: var(--opacity-text-low);
}

.dropdownHeading {
  @include line-clamp(1);
}

.detailsButton {
  margin-left: 10rem - $title-padding;
  // Nudges the round button over to optically align it (and provide a tiny bit
  // more space)
  margin-right: -2rem;

  &[disabled] {
    opacity: 0 !important; // Fights with specificity in Button
  }
}

.counters {
  margin-top: 4rem;
  // Aligns the counters with the text from the category
  margin-left: $title-left-padding - $title-padding;
}
