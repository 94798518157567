@import "../../global";

.options {
  position: absolute;
  left: 0;
  top: 85rem;
  max-height: 40vh;
  background: theme-color(dialog-background);
  border-radius: 12rem;
  border: 1rem solid theme-color(border);
  box-shadow: $dialog-box-shadow;
  overflow-y: scroll;
  color: theme-color(text);
}

.option {
  display: flex;
  align-items: center;
  padding: 0 15rem;
  height: 55rem;
  font-size: 16rem;
  font-weight: $fw-medium;
  white-space: nowrap;
  border-bottom: 1rem solid theme-color(border);
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }

  &.selected {
    background: theme-color(text, var(--opacity-ui-lowest));
  }
}

.avatar {
  --avatar-size: 24rem;

  margin-right: 12rem;
  flex-shrink: 0;
}

.nickname {
  flex: 1 0;
  order: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  font-variant: no-contextual;
}

.createNew {
  flex: 0 0;
  order: 2; // The order in the markup is different for accessibility
  padding: 0 12rem;
  margin-left: 12rem;
  border: 1rem solid theme-color(border);
  border-radius: 8rem;
  font-size: 14rem;
  font-weight: $fw-regular;
  line-height: 32rem;
}
