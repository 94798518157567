@use "sass:math";

@import "../../global";

$contextual-menu-margin: 6rem;
$contextual-menu-border-width: 1rem;
$contextual-menu-vertical-padding: 2rem;
$contextual-menu-horizontal-padding: 4rem;
$contextual-menu-link-margin: 2rem;
$contextual-menu-link-horizontal-padding: 6rem;

// 2rem is the real value, but it also requires the padding and border to be accounted
// for to correctly calculate the margin
$contextual-submenu-margin: $contextual-menu-horizontal-padding +
  $contextual-menu-border-width + 2rem;

$contextual-submenu-offset: $contextual-menu-border-width +
  $contextual-menu-vertical-padding + $contextual-menu-link-margin;

@value contextualMenuMargin: #{math.div($contextual-menu-margin, 1rem)};
@value contextualSubmenuMargin: #{math.div($contextual-submenu-margin, 1rem)};
@value contextualSubmenuOffset: #{math.div($contextual-submenu-offset, 1rem)};

.wrapper {
  position: fixed;
  display: flex;
  max-height: calc(100vh - #{$contextual-menu-margin * 2});
  z-index: $contextual-menu-z-index;
  // Left and top are needed otherwise the wrapper is assumed to be positioned at
  // the point of the parent which causes strange behaviours. These are overruled
  // by the JS anyway
  left: 0;
  top: 0;

  &[hidden] {
    visibility: hidden;
  }
}

// This is the class that is overwritten in ContextualMenu so for setting widths
// in situ, use className and set `width` and `max-width` to the specified sizes.
.menu {
  background: theme-color(dialog-background);
  border: $contextual-menu-border-width solid theme-color(border);
  border-radius: 8rem;
  box-shadow: $dialog-box-shadow;
  color: theme-color(text);
  font-size: 12rem;
  line-height: 14rem;
  padding: $contextual-menu-vertical-padding $contextual-menu-horizontal-padding;
  flex: 1;
  max-width: 240rem;
  min-width: min-content;
  // This must be 100vh because the parent has no explicit height so 100% doesn't work
  max-height: calc(100vh - #{$contextual-menu-margin * 2});
  text-align: left;
  overflow-y: auto;
  overflow-x: hidden;

  [role="separator"] {
    margin: 4rem 0;

    &:last-child,
    &:first-child {
      display: none;
    }
  }
}

.heading {
  margin: 10rem 0 4rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 $contextual-menu-link-horizontal-padding;
  opacity: 1;
  font-size: 11rem;
  color: theme-color(text, var(--opacity-text-mid));

  &:first-child {
    margin-top: 6rem;
  }
}

.headingHasButton {
  display: flex;
  justify-content: space-between;

  > button {
    margin: -2rem 0 -2rem 8rem;
  }
}

$link-menu-border-radius: $dialog-border-radius - 6rem;

.link {
  border-radius: $link-menu-border-radius;
  color: currentColor;
  cursor: pointer;
  display: block;
  font-weight: $fw-regular;
  margin: $contextual-menu-link-margin 0;
  padding: 5rem $contextual-menu-link-horizontal-padding;
  // Accommodates any item in the list having a submenu for consistent spacing
  // it also reads slightly more easily when there is space on the right hand side
  // – it optically enforces the left alignment
  padding-right: $contextual-menu-link-horizontal-padding + 14rem;
  position: relative;
  text-align: inherit;
  text-decoration: none;
  transition: none;
  width: 100%;

  &:hover:not([disabled]) {
    background: theme-color(text, var(--opacity-ui-lowest));
    text-decoration: underline;
  }

  @include focus-ring(
    $selector: "&::after",
    $bottom: -2rem,
    $left: -2rem,
    $right: -2rem,
    $top: -2rem,
    $border-radius: $link-menu-border-radius + 2rem
  );

  &.negative {
    color: theme-color(negative);
  }

  &[disabled] {
    opacity: var(--opacity-text-low);
    cursor: default;
  }
}

.linkIcon {
  position: absolute;
  right: $contextual-menu-link-horizontal-padding;
  top: 50%;
  width: 10rem;
  height: 10rem;
  margin: -5rem -2rem;
  opacity: var(--opacity-text-low);

  path {
    fill: currentColor;
  }

  .link:hover:not([disabled]) &,
  .link:focus-visible & {
    opacity: 1;
  }
}

.linkSubmenu {
  .linkIcon {
    margin: -3rem 0;
    width: 5rem;
    height: 6rem;
  }
}

.linkCategory {
  display: flex;
  word-break: break-word;

  &::before {
    content: "";
    background: category-color(default, 1, var(--color-text));
    height: 8rem;
    width: 8rem;
    margin: 3rem 6rem 0 0;
    border-radius: 50%;
    flex-shrink: 0;
  }
}

.blocks {
  display: flex;

  > span {
    @include line-clamp(2);
  }

  .blockIcon {
    position: relative;
    margin: 2.5rem 6rem 0 0;
    width: 8rem;
    height: 10rem;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 2rem;
      width: 6rem;
      border: 1rem solid category-color(default, 1, var(--color-text));
      flex-shrink: 0;
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      height: 2rem;
      width: 6rem;
      border: 1rem solid category-color(default, 1, var(--color-text));
      flex-shrink: 0;
    }
  }

  padding-left: 16rem;
}