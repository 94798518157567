@import "../../global";

$counter-dash-array: 94px; // Safari doesn't support rems for these calculations so we have to use pixels

.wrapper {
  position: relative;
}

.circle {
  display: block;
  transform: rotate(-90deg);
  width: $character-counter-size;
  height: $character-counter-size;

  circle {
    fill: none;
    stroke-width: 2rem;
  }
}

.rail {
  stroke: currentColor;
  stroke-opacity: 0.2;
}

.progress {
  stroke: currentColor;
  stroke-dasharray: $counter-dash-array;
  stroke-dashoffset: calc(
    #{$counter-dash-array} - (#{$counter-dash-array * 0.01} * var(--character-percent))
  );
  transition: stroke-dashoffset 150ms ease-out;

  .zero & {
    // Ensures that there is no sliver when percentage is 0% (caused by the imperfect
    // $counter-dash-array measurement)
    stroke: transparent;
  }

  .warning & {
    stroke: theme-color(warning);
  }

  .error & {
    stroke: theme-color(negative);
  }
}

.counter {
  color: theme-color(warning);
  font-weight: $fw-medium;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 14rem;
  line-height: $character-counter-size;
  opacity: 0;
  font-variant-numeric: tabular-nums;
  text-align: center;

  .warning & {
    opacity: 1;
  }

  .error & {
    color: theme-color(negative);
    opacity: 1;
    transform: translateX(
      -0.05em
    ); // Optically centers the counter when it is negative
  }
}

.tooltip {
  display: grid;

  > span {
    grid-area: 1 / 1;
    text-align: center;
  }

  [aria-hidden] {
    visibility: hidden;
  }
}

.tooltipCounter {
  font-variant-numeric: tabular-nums;
}
