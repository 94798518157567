@import "../../global";

.wrapper {
  display: block;
  padding: 6rem 0;
  pointer-events: none;
  position: fixed;
  z-index: $contextual-menu-z-index + 1;

  &[hidden] {
    visibility: hidden;
  }
}

.tooltip {
  border-radius: 6rem;
  background: theme-color(600);
  border: 1rem solid theme-color(border);
  box-shadow: $dialog-box-shadow;
  color: theme-color(100);
  padding: 6rem 9rem 7rem;
  align-items: center;
  display: flex;
  top: 0;
  left: 0;
  font-size: 12rem;
  line-height: 15rem;
  font-weight: $fw-medium;
  max-width: 200rem;
  word-break: break-word;
}

.keys {
  white-space: nowrap;
  margin-left: 6rem;
  margin-right: -3rem;

  kbd {
    background: theme-color(100, var(--opacity-ui-low));
    border-radius: 2rem;
    font-weight: $fw-bold;
    display: inline-block;
    height: 17rem;
    font-size: 12rem;
    min-width: 17rem;
    padding: 2rem;
    padding-top: 1rem; // Vertically centers the key
    margin: -1rem 0 -1rem 4rem;
    text-align: center;
    vertical-align: top;
  }
}
