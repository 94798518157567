@import "./../../global";

.colors {
  display: flex;
  flex-wrap: wrap;
}

.color {
  color: category-color(default);
  background: currentColor;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 32rem;
  width: 32rem;
  border-radius: 50%;
  margin: 4rem 13rem 4rem 0; // 13rem makes them line up with the colours /shrug
  flex-shrink: 0;
  position: relative;

  &:last-child {
    margin-right: 0;
  }

  &::after {
    background: currentColor;
    border-radius: 50%;
    bottom: 4rem;
    box-sizing: border-box;
    content: "";
    left: 4rem;
    position: absolute;
    right: 4rem;
    top: 4rem;
  }

  svg {
    z-index: 3;
    opacity: 0;
    transform: scale(0.85);
    transition: transform 125ms ease-in-out;

    input:checked ~ & {
      opacity: 1;
      transform: none;
    }
  }

  path {
    fill: category-color(text);
  }

  input {
    appearance: none;
    cursor: pointer;
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
    width: 100%;
    opacity: 0;
    z-index: 4;

    @include focus-ring($selector: "+ .focusRing");
  }
}
