@import "global";

@mixin create-colors($color-set) {
  @each $color-key, $color-hex in $color-set {
    --color-#{$color-key}: #{hex-to-rgb($color-hex)};
  }
}

@each $color-number, $color-set in $category-color-sets {
  [data-category-color="#{$color-number}"] {
    @each $category-shade in $category-color-shades {
      $color: get-category-shade($color-number, $category-shade);

      --category-#{$category-shade}: #{hex-to-rgb($color)};
    }
  }
}

@mixin light-mode-colors {
  @include create-colors($colors-light);

  --opacity-ui-lowest: 0.05;
  --opacity-ui-low: 0.1;
  --opacity-ui-mid: 0.25;

  --opacity-text-low: 0.6;
  --opacity-text-mid: 0.75;

  --box-shadow: #{rgba(black, 0.1)};
}

@mixin dark-mode-colors {
  @include create-colors($colors-dark);

  --opacity-ui-lowest: 0.1;
  --opacity-ui-low: 0.2;
  --opacity-ui-mid: 0.4;

  --opacity-text-low: 0.5;
  --opacity-text-mid: 0.7;

  --box-shadow: #{rgba(black, 0.15)};
}

@include light-theme {
  @include light-mode-colors;
}

@include dark-theme {
  @include dark-mode-colors;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
  box-sizing: inherit;
  color: inherit;
  font: inherit;
  text-decoration: inherit;
  list-style: none;
  background: none;
}

html {
  /* This makes 1rem = 1px with browser's default font settings */
  font: normal calc(1em / 16) / 1 sans-serif;
  text-decoration: none;
  box-sizing: border-box;
  user-select: none;
  cursor: default; /* Needed to stop Safari showing the text cursor even where selection is disabled */
}

@font-face {
  font-family: $font-heading;
  font-style: normal;
  font-weight: $fw-heading;
  font-display: swap;
  src: url("./fonts/RRI-Heading-#{$fw-heading}.woff2") format("woff2");
}

$font-weights: $fw-regular, $fw-medium, $fw-bold;

@each $font-weight in $font-weights {
  @font-face {
    font-family: $font-body;
    font-style: normal;
    font-weight: $font-weight;
    font-display: swap;
    src: url("./fonts/RRI-Body-#{$font-weight}.woff2") format("woff2");
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: $ff-body;
  overflow: hidden; /* Disables scroll bouncing */
}

body {
  background: $body-background url("assets/background.png") center top / cover
    no-repeat;
  color: $body-color;
}

html,
body,
#react-root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

input:invalid {
  /* Firefox shows a red shadow if for example
     the email is incomplete in an email input */
  box-shadow: none;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
}

/* Electron window drag behaviour */
body {
  /* User can use anything in the body to drag around the
     Electron window, except the elements in the rule below */
  -webkit-app-region: drag;
}
a,
button,
input,
textarea,
select,
li {
  /* These elements are too small/specific, or they are clearly
     triggers for other user interactions: disable window drag */
  -webkit-app-region: no-drag;
}
