@import "../../global";

.wrapper {
  display: flex;
}

.item {
  height: 16rem;
  display: inline-flex;
  align-items: center;
  font-variant-numeric: tabular-nums;
  opacity: var(--opacity-text-mid);
  font-size: 12rem;
  line-height: 16rem;
  font-weight: $fw-bold;
  transition: opacity $transition;

  & + & {
    // Design says 16px, but this is smaller to accommodate the min-width on the labels
    margin-left: 6rem;
  }
}

.label {
  min-width: var(--counter-min-width, 2ch);
  text-align: left;
}

.icon {
  height: 16rem;
  margin-right: 6rem;
  width: 16rem;

  path {
    fill: currentColor;
  }
}

.iconSmall {
  // These are strangely sized in the designs rather than using the standard 16x16
  height: 14rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.actions {
  --counter-min-width: 4.5ch;
}
