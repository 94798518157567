@import "../../global";

$error-line-height: 14rem;
$error-icon-height: 16rem;

.error {
  color: theme-color(negative);
  font-weight: $fw-medium;
  font-size: 12rem;
  // This isn't perfect, but it suffices to transition the error element open when it is needed
  height: $error-icon-height + $error-line-height;
  opacity: 1;
  transition: opacity 125ms 125ms linear, height 125ms linear;
  overflow: hidden;
  display: flex;
  margin-top: 40rem;

  a,
  button {
    text-decoration: underline;
    cursor: pointer;
  }

  > span {
    @include line-clamp(2);

    height: $error-line-height * 2;
    line-height: $error-line-height;
    margin-top: 2rem;
    flex: 1;
  }

  svg {
    margin-right: 8rem;
    width: 16rem;
    height: 16rem;
    flex-shrink: 0;

    path {
      fill: currentColor;
    }
  }

  &[aria-hidden="true"] {
    height: 0;
    opacity: 0;
    transition: opacity 125ms linear, height 125ms 125ms linear;
  }
}
