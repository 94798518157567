@import "../../global";
@import "../Card/Card.module.scss";

$action-card-chunk-margin: $card-buttons-margin * 0.5;
$action-card-top-padding: $card-top-padding - $action-card-chunk-margin;
$action-counter-width: 32rem;
$action-card-icon-size: 16rem;

.card {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr auto;
  min-height: $action-card-height;
  cursor: pointer;
}

.banner {
  grid-column: 1 / span 2;
  grid-row: 1;
  z-index: 1;
}

.content {
  grid-column: 2;
  grid-row: 2;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  padding-left: $card-padding;
  padding-top: $card-top-padding;
  padding-bottom: $card-bottom-padding;

  .quickView {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .info {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
  }
}

.blockOutcome {
  grid-column: 2;
  grid-row: 3;
  margin: 8rem;
  margin-top: - calc($card-bottom-padding * 0.25);
  margin-bottom: $card-bottom-padding;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  max-width: 90%;
  font-weight: 400;
  font-size: 13rem;
  color: #{theme-color(200)};
  border: 1rem solid #{theme-color(400)};
  border-radius: 4rem;
  padding: 4rem;
  gap: 10rem;
  min-width: 0;

  --button-background-hover: transparent;

  ::after {
    border: 0;
  }

  @include focus-ring(
    $selector: "&::before",
    $bottom: -4rem,
    $left: -4rem,
    $top: -4rem,
    $right: -4rem,
    $border-radius: 6rem,
  );

  .labelIcon {
    fill: currentColor;
    width: $action-card-icon-size;
    height: $action-card-icon-size;
    min-width: $action-card-icon-size;
  }

  .infoBlockOutcome {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
    text-transform: none;
    max-width: 100%;
  }
}



// TODO: Come back and fix this now that the checkbox on the right obscures
// the number
// .contentWithNumber {
//   padding-left: $action-counter-width;
// }

.number {
  position: absolute;
  height: 100%;
  top: 0;
  width: $action-counter-width;
  text-align: right;
  left: 0;
  display: flex;
  padding-left: $card-padding * 0.25;
  align-items: center;
  justify-content: center;
  padding-bottom: 3rem;
  font-size: $card-font-size;
  font-weight: $fw-bold;
  opacity: var(--opacity-text-mid);
}

.completionButton {
  grid-column: 1;
  grid-row: 1 / span 3;
  box-shadow: $inset-box-shadow;
  display: flex;
  cursor: pointer;
  width: $action-item-completion-button-width;
  position: relative;
  top: 0;
  right: 0;
  height: 100%;
  align-items: center;
  justify-content: center;
  border-top-left-radius: $card-border-radius;
  border-bottom-left-radius: $card-border-radius;
  padding-bottom: 3rem; // Optically balances the shadow
  transition: padding-top $transition;

  // Allows the .completionButton to underlap the banner so the border radius
  // is maintained, but ensures that the position of the checkbox updates in
  // order to keep its vertical alignment centred.
  .banner[aria-hidden="false"] ~ & {
    padding-top: $card-banner-height;
  }

  @include focus-ring(
    $selector: "&::after",
    $border-radius: $card-border-radius + 4rem 2rem 2rem $card-border-radius +
      4rem,
    $left: -4rem,
    $top: -4rem,
    $bottom: -4rem,
    $right: -4rem
  );

  &[disabled] {
    cursor: default;
    opacity: $button-disabled-opacity;
  }
}

.actionableButton {
  @include focus-ring(
    $selector: "&::before",
    $bottom: -4rem,
    $left: -4rem,
    $top: -4rem,
    $right: -4rem,
    $border-radius: 6rem,
  );
}

.label {
  align-self: flex-start;
  flex-grow: 1;
  text-align: left;
  padding-right: $card-padding;
  padding-top: $action-card-chunk-margin;
  padding-bottom: $action-card-chunk-margin;
  overflow: hidden;
  text-overflow: ellipsis;
}

.emailLabel {
  display: block;
  font-weight: $fw-bold;
  font-size: 9rem;
  line-height: 14rem;
  text-transform: uppercase;
  opacity: var(--opacity-text-low);
  margin: -1rem 0 2rem;
  height: 12rem;
  overflow: hidden;
}

.description {
  @include line-clamp(2);

  font-size: $card-title-font-size;
  line-height: $card-line-height;
  word-break: break-word;

  span {
    // Mentions are spans
    font-weight: $fw-bold;
    text-decoration-color: inherit;
  }
}

.descriptionCompleted {
  opacity: var(--opacity-text-low);
  text-decoration: line-through;
}

.leveraged {
  font-size: 11rem;
  line-height: 15rem;
  opacity: var(--opacity-text-mid);
  margin: 3rem 0 -2rem -1rem; // Offsets the icon bounds
  display: flex;
  transition: color $transition;
  padding-right: 8rem;
  overflow: hidden;
  mask: linear-gradient(to right, black calc(100% - 8rem), transparent) center
    right;

  svg {
    flex-shrink: 0;
    height: 12rem;
    width: 12rem;
    margin: 1rem 2rem 0 0;
  }

  path {
    fill: currentColor;
  }
}

.leveragedCompleted {
  opacity: var(--opacity-text-low);
}

// By setting the height to be the height of one line and aligning everything to flex-end
// it allows the "Leveraged by" label to slot in if there is space, but be hidden if there isn’t.
// The negative margins are to ensure that it takes up the same space it would otherwise whilst
// ensuring that no descenders/ascenders are cut off by the text-overflow.
.leveragedLabel {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
  height: 14rem;
  text-overflow: ellipsis;
}

.leveragedName {
  font-weight: $fw-medium;
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: $action-card-chunk-margin;
  padding-bottom: $action-card-chunk-margin;
  padding-right: $card-padding - $card-buttons-margin;
}

.buttonTime {
  padding: 0;
  align-items: stretch;

  select {
    // These reverts are required to ensure that Windows machines don't show it as white text
    // when .cardWithColor is present, it also resets the font-weight
    color: revert;
    font-weight: revert;
    cursor: pointer;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @include focus-ring(
      $selector: "+ .focusRing",
      $border-radius: $button-square-border-radius + 4rem
    );
  }

  svg {
    margin: 1rem 6rem 0 0;
    width: $select-arrow-width;
    height: $select-arrow-height;
  }
}

.dateTimeLabel {
  padding-top: $action-card-chunk-margin;
  padding-bottom: $action-card-chunk-margin;
  padding-right: $card-padding - $card-buttons-margin;
  font-size: $card-font-size;
  line-height: $card-line-height;
  color: #{theme-color(200)};
}

.pastDue {
  color: #{theme-color(negative)};
}
