@import "../../global";

.form {
  flex-shrink: 0;
}

.loading {
  // Stops the user from clicking anything while the form is loading
  pointer-events: none;
}

.wrapper {
  max-width: 360rem;
}

.error {
  color: lighten(
    $color-negative,
    15%
  ); // Fixes reduced contrast against dark background
}

.buttons {
  margin-top: 20rem;
  display: grid;
  grid-gap: 16rem 0;

  @include min-width(320) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 16rem;
  }
}

.bottom {
  margin-top: 40rem;
}

.message {
  margin-top: 16rem;
}
