@import "../../global";

.summary {
  cursor: pointer;
  list-style: none;
  display: table;
  position: relative;
  padding: 4rem; // Increases the hit state slightly
  margin: 24rem -4rem 8rem;

  &::-webkit-details-marker {
    display: none;
  }

  @include focus-ring($selector: "&::after", $border-radius: 8rem);

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: currentColor;
    border-radius: 4rem;
    opacity: 0;
    content: "";
    transition: opacity $transition;
  }

  &:hover {
    &::before {
      opacity: var(--opacity-ui-lowest);
    }
  }
}

.summaryHeading {
  display: flex;
  align-items: center;
  position: relative;
  margin: 0;

  svg {
    transform: rotate(-90deg);
    transform-origin: center center;
    margin-left: 4rem;
    transition: $transition;
    transition-property: opacity, transform;
    flex-shrink: 0;

    .details[open] & {
      transform: none;
    }
  }

  path {
    fill: currentColor;
  }
}
