@import "../../global";

$create-dialog-width: 520rem;
$create-dialog-width-wide: 900rem;

$create-dialog-vertical-padding: 20rem;
$create-dialog-horizontal-padding: 24rem;

$create-dialog-border-radius: 30rem;

$create-dialog-input-font-size: 18rem;
$create-dialog-input-line-height: 26rem;
$create-dialog-input-padding: 8rem;
$create-dialog-input-font-weight: $fw-medium;

.dialog {
  background: theme-color(dialog-background);
  border: 0;
  position: relative;
  max-width: $create-dialog-width;
  border-radius: $create-dialog-border-radius;
}

.wide {
  max-width: $create-dialog-width-wide;
}

.form {
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: $create-dialog-vertical-padding $create-dialog-horizontal-padding;

  > button {
    margin: 0;
  }
}

.counter {
  margin: 0 0 0 $create-dialog-horizontal-padding;
}

.label {
  flex: 1;
}

.input {
  color: theme-color(text);
  font-size: $create-dialog-input-font-size;
  line-height: $create-dialog-input-line-height;
  padding: $create-dialog-input-padding 0;
  font-weight: $create-dialog-input-font-weight;
  margin-bottom: $create-dialog-input-padding * -1;
  margin-top: -6rem; // Reduces the default spacing from the label before for a tighter arrangement
  display: block;
  width: 100%;
  font-variant: no-contextual;

  &::placeholder {
    color: theme-color(text, $input-placeholder-opacity);
    font-weight: $input-placeholder-font-weight;
  }

  @include focus-ring(
    $selector: "+ .focusRing",
    $border-radius: $create-dialog-border-radius - 2rem,
    $left: 2rem,
    $bottom: 2rem,
    $right: 2rem,
    $top: 2rem
  );
}

// Allows for the use of el.scrollHeight to determine how many lines are currently visible
// See CreateBlockDialog for an example
textarea.input {
  resize: none;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
  margin-top: $create-dialog-input-padding + 2rem;
}
