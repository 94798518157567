@import "../../global";

.navButton {
  margin-right: $nav-item-spacing;
  border: 2em solid transparent;
  border-radius: $nav-item-radius;

  &:focus-within {
    border: 2rem solid #{$color-focus-ring};
  }

  &[data-category-color] {
    svg {
      background: rgba(var(--category-default, var(--color-cat-fallback)), 1);
      border-radius: $button-square-border-radius;
      padding: 4rem;
      height: 24rem;
      width: 24rem;

      path {
        fill: $colors-light-600;
      }
    }
  }
}
