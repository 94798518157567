@import "../../global";

.divider {
  display: block;
  position: relative;
  width: 100%;
  height: 1rem;
  background: theme-color(border);
  margin: 14rem 0;
}

.vertical {
  height: 32rem;
  width: 1rem;
  margin: 0 14rem;
}
