@import "./../../global";

@import "../CreateDialog/CreateDialog.module.scss"; // Uses the styles for the inputs

$extras-height: 327rem;
$extras-margin: 20rem;
$icons-per-row: 9;

$card-small-font-size: 12rem;

$create-dialog-bottom-border-radius: 24rem;
$create-dialog-bottom-width: 426rem;
$create-dialog-bottom-height: 100rem;
$create-dialog-bottom-margin-bottom: 20rem;
$create-dialog-bottom-margin-right: 16rem;

.form {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;

  padding: $panel-padding;
  font-weight: $fw-regular;
  font-size: $card-font-size;
  line-height: $item-line-height;
}

.dialogTitle {
  display: flex;
  flex: 1;
  flex-direction: row;
  color: theme-color(100);
}

.inputWrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: theme-color(100);
  width: 100%;
  gap: 4rem;

  .inputFields {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: $card-small-font-size;
    padding: 0;
    margin: 0;

    label {
      font-weight: $fw-regular;
      line-height: $item-line-height;
    }

    .inputLabel {
      margin-right: 3rem;
      color: theme-color(200);
      font-size: $card-font-size;
    }
  }
}

.actionsWrapper {
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  color: theme-color(200);
  justify-content: flex-end;
  width: 100%;
  gap: 8rem;

  .cancelButton {
    margin-right: 8rem;
  }
}

.buttonDueDateLabel {
  transition: opacity $transition;
  font-weight: $fw-regular;
  color: currentColor;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 0 2rem;

  span {
    padding-top: 1rem;
    padding-left: 2rem;

    text-transform: none;
  }
}

.timeWrapper {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin: 8rem 0;

  span {
    margin: 0;
  }
}

.timeSelect {
  font-weight: $fw-regular;
}

.dialog {
  border-radius: $create-dialog-bottom-border-radius;
  width: $create-dialog-bottom-width;
  height: $create-dialog-bottom-height;
  margin-bottom: $create-dialog-bottom-margin-bottom;
  margin-right: $create-dialog-bottom-margin-right;
}

.extras {
  background: theme-color(dialog-background);
  border: 1rem solid theme-color(border);
  padding: $create-dialog-vertical-padding $create-dialog-horizontal-padding;
  position: absolute;
  top: calc(100% + #{$extras-margin});
  height: $extras-height;
  left: 0;
  width: 100%;
  border-radius: $dialog-border-radius;
  box-shadow: $dialog-box-shadow;
  color: theme-color(text);
}
