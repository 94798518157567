@import "../../global";

$grid-cell-height: 60rem;
$bottom-fade-height: $floating-button-size-rem + ($floating-button-offset * 2);
$calendar-lines: theme-color(400);

.main {
  font-size: 16rem;
  grid-area: 1 / 3;
  position: relative;
  overflow: hidden;
  z-index: 3;
  @include sidebar-container-animate-out;
}

.expanded {
  .main {
    @include sidebar-container-animate-in;
  }
}

.calendar {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow-y: auto;
  // Visibility is used to ensure that nothing is focusable when the calendar is hidden,
  // but it should only be set to hidden after the opacity transition has happened.
  transition: opacity $duration-state ease-in, visibility 0ms;
  // These competing animations require a bit of extra GPU work so using `will-change`
  // forces these onto their own paint layers.
  will-change: transform;
}

.container {
  --hours-column-width: 72rem;

  display: flex;
  flex-direction: column;
  height: 100%;

  // The breakpoint at which the heading 0 size changes is used to make
  // this change so they coincide
  @include max-width($heading-level-0-large-font-size-breakpoint) {
    --hours-column-width: 48rem;
  }
}

.header {
  flex: 0 0 auto; // Safari needs this
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: $sidebar-margin;
  margin-bottom: $panel-padding * 0.5;
  margin-top: $panel-padding-top;
  flex-wrap: wrap;
}

.title {
  margin-bottom: $panel-padding * 0.5;
  margin-right: $panel-padding;
  margin-top: 0;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  height: $heading-level-0-line-height * 1em;
  line-height: $heading-level-0-line-height;
  align-content: flex-end;
  overflow: hidden;
  margin-left: -1px;
}

.titlePartLong,
.titlePartShort {
  display: flex;
  align-items: flex-start;
  padding-left: 1px;
  white-space: nowrap;
}

.titlePartLong {
  flex-grow: 1;
}

.titlePartShort {
  display: flex;
  align-items: flex-start;
  width: 1px;
}

.titleChunkTwo {
  margin: 0 0 0 0.1em;
  opacity: var(--opacity-text-low);
}

.dialogTabBar {
  width: 150rem;
}

.headerPanel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.buttons {
  display: flex;
  margin-bottom: $panel-padding * 0.5;
  max-height: 32rem;

  button {
    margin: 0 8rem 0 0;
  }

  .createEventButton {
    margin-left: 24rem;
    margin-right: 24rem;
  }

  .todayButton {
    margin-right: 0;
  }
}

.calendarWrapper {
  display: flex;
  flex-direction: column;
  padding-right: $sidebar-margin;
  overflow: hidden;
}

.daysRow {
  flex: 0 0 auto; // Safari needs this
  display: flex;
}

.leftMargin {
  // We need this element to draw the bottom border
  box-shadow: inset $calendar-lines 0 -1rem 0;
}

.leftMargin {
  flex: 0 0 var(--hours-column-width);
}

.dayHeader {
  box-sizing: border-box;
  flex: 1;
  box-shadow: inset $calendar-lines 0 -1rem 0;
  text-align: center;
  padding-bottom: 16rem;
  opacity: var(--opacity-text-mid);
  position: relative;

  &::before {
    content: "";
    position: absolute;
    bottom: 10rem;
    left: 50%;
    margin: -3rem;
    height: 6rem;
    width: 6rem;
    background: currentColor;
    transform: scale(0);
    transition: transform $transition;
    border-radius: 3rem;
  }

  &.today {
    opacity: 1;

    &::before {
      transform: scale(1);
    }
  }

  &.past {
    opacity: var(--opacity-text-low);
  }
}

.dayName {
  font-weight: $fw-bold;
  margin: 0;
}

.dayNumber {
  font-weight: $fw-bold;
  margin: 0 0 6rem;
}

.scrollable {
  --event-card-scroll-margin-bottom: #{$bottom-fade-height + 50rem};

  overflow-y: scroll;
  // Bottom fade out
  mask: linear-gradient(to top, transparent, black #{$bottom-fade-height});

  &::after {
    // This element has the exact size of the bottom fading area.
    // His presence ensures that the user has a way to scroll all
    // the contents out of the fading area.
    content: "";
    display: block;
    height: $bottom-fade-height;
  }
}

.scrollableContent {
  display: flex;
  margin-top: -1rem; // Accounts for the first grid-line
}

.hours {
  width: var(--hours-column-width);

  div {
    width: inherit;
    height: $grid-cell-height;
    text-align: right;
    padding-right: calc(var(--hours-column-width) / 6);
    font-size: 10rem;
    font-weight: $fw-medium;
    line-height: 16rem;
    transform: translateY(
      -7rem
    ); // Vertically centers the number against the gridline

    &:first-child {
      // Hide the label for 12AM, it doesn't look good,
      // but keep the space occupied to not break the layout
      visibility: hidden;
    }
  }
}

.eventsArea {
  flex-grow: 1;
  position: relative;
  display: flex;

  &.dragging {
    cursor: grabbing;
  }

  &.resizing {
    cursor: ns-resize;
  }
}

.grid {
  flex-grow: 1;
  display: flex;
  box-shadow: inset $calendar-lines -1rem -1rem 0;
}

.allDayGrid {
  max-height: 320rem;
  overflow-y: scroll;
}

.gridPast {
  background: repeating-linear-gradient(
    30deg,
    transparent,
    transparent 5rem,
    $calendar-lines 5rem,
    $calendar-lines 6rem
  );
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.column {
  flex-grow: 1;
  box-shadow: inset $calendar-lines 1rem 0 0;
  background: repeating-linear-gradient(
    0deg,
    transparent 0rem,
    transparent $grid-cell-height - 1rem,
    $calendar-lines $grid-cell-height - 1rem,
    $calendar-lines $grid-cell-height
  );
}

.allDayEvents {
  flex: 0 0 auto; // Safari needs this
  display: flex;
  margin-top: -1rem;

  .grid {
    position: relative;
  }
}

.now {
  height: 2rem;
  background: currentColor;
  pointer-events: none;

  &::before {
    content: "";
    position: absolute;
    left: -3rem;
    top: -2rem;
    border-radius: 100%;
    width: 6rem;
    height: 6rem;
    background: currentColor;
  }
}

.dropzone {
  --collapse-transition: #{$transition-capture-list-out};
  --hide-transition: #{$duration-state} ease-out;

  // As an MVP we don't have any DND indicator in the calendar as the
  // global dropzone is confusing and an event specific one that takes its
  // size is not part of the scope of the simple implementation.
  opacity: 0;
  pointer-events: none;
  bottom: 0;
  top: 0;
  height: 100%;
  transition: right var(--collapse-transition), height var(--hide-transition);

  :global(.sortable-ghost) {
    top: 0;
    bottom: 0;
  }
}

.dropzoneHidden {
  --hide-transition: #{$duration-state} ease-in;

  height: 0;
}
