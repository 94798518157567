@import "../../global";

.container {
  text-align: center;
}

.action {
  --inset-box-shadow: #{theme-color(card-bottom-shadow)};

  background: theme-color(card-background);
  box-shadow: $inset-box-shadow;
  border-radius: $card-border-radius;
  height: $action-card-height;
  color: theme-color(text, var(--opacity-ui-low));
  display: flex;
  padding: 0 $item-padding 2rem;
  align-items: center;
  margin-bottom: $card-margin;
  position: relative;

  &::before {
    content: "";
    background: currentColor;
    border-radius: 2rem;
    height: 6rem;
    max-width: 180rem;
    flex: 1;
    margin-right: 12rem;
  }

  &::after {
    content: "";
    background: currentColor;
    border-radius: 50%;
    width: $action-item-completion-ring-width;
    height: $action-item-completion-ring-width;
    margin-left: auto;
  }

  &:nth-of-type(2) {
    mask: linear-gradient(black, rgba(black, 0.5));

    &::before {
      max-width: 120rem;
    }
  }

  &:nth-of-type(3) {
    mask: linear-gradient(rgba(black, 0.5), rgba(black, 0));

    margin-bottom: 0;

    &::before {
      max-width: 220rem;
    }
  }
}

.message {
  opacity: var(--opacity-text-mid);
  font-size: 14rem;
  line-height: 20rem;
  font-weight: $fw-regular;
  max-width: 20em;
  margin: 0 auto 8rem;
  white-space: pre-line; // Allows for line breaks
}
