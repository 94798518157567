@import "../../global";

$event-line-height: 14rem;

.event {
  position: absolute;
  width: 100%;
  height: 100%;
  // .event has the onCLick handler for keyboard accessibility, but when using
  // the mouse it should be triggered only when clicking on one children box.
  // This is achieved disabling pointer-events here, and enabling it on the
  // children boxes.
  pointer-events: none;

  @include focus-ring($selector: "> .box::after", $border-radius: 14rem);

  &:focus {
    // Ensures that the event card sits above others later in the DOM when focused
    // to keep the focus ring over the top of the others
    z-index: 1;
  }

  &:hover {
    .removeFromCalendarButton {
      opacity: 0.85;
    }
  }
}

.box {
  --inset-box-shadow: #{theme-color(event-bottom-shadow)};

  pointer-events: all; // See above comment to understand why this is needed
  color: theme-color(100);
  background: theme-color(page-background);
  border-radius: $card-border-radius;
  font-weight: $fw-medium;
  font-size: 12rem;
  line-height: $event-line-height;
  text-align: left;
  display: flex;
  scroll-margin-top: var(--event-card-scroll-margin-top, 50rem);
  scroll-margin-bottom: var(--event-card-scroll-margin-bottom, 50rem);

  // By using a pseudo element for the background, it becomes much more straightforward
  // to handle the opacity change for the past event given the complexity in difference
  // between internal (with their colours) and external events
  &::before {
    box-shadow: $inset-box-shadow;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: theme-color(event-background);
    border-radius: inherit;
  }

  button.event & {
    cursor: pointer;
  }

  &.dragging {
    opacity: 0.5;
  }

  &.internal {
    --inset-box-shadow: #{category-color(dark)};

    box-shadow: 0 2rem 10rem rgba(black, 0.2);
    color: category-color(text);

    &::before {
      // Because an event can be the whole height of the page, there are
      // specific pixel value colour stops for the gradient to keep the text
      // legible by minimising the contrast reduction from the white gradient
      background: category-color(default)
        linear-gradient(
          // Designs are 15%, but has been reduced to 10% for a11y
          category-color(text, 0.1) 0,
          category-color(text, 0.05) 30rem,
          category-color(text, 0) 60rem
        );
    }
  }

  &.past {
    --inset-box-shadow: #{rgba(black, 0.1)};

    color: theme-color(text, var(--opacity-text-low));

    &.internal {
      box-shadow: none;
      color: category-color(text-reversed, 0.5);

      @at-root {
        @include light-theme {
          .event {
            --event-ring-color-active: #{category-color(text)};
            --event-ring-background-active: #{category-color(darker)};
          }
        }

        @include dark-theme {
          .event {
            --event-ring-color-active: #{category-color(text-reversed)};
            --event-ring-background-active: #{category-color(light)};
          }
        }
      }

      .completionRing {
        --ring-shadow: #{category-color(text-reversed, 0.1)};
        --ring-background: transparent;
        --ring-border: #{category-color(text-reversed, 0.5)};
        --ring-border-hover: #{category-color(text-reversed)};
        --ring-color-active: var(--event-ring-color-active);
        --ring-background-active: var(--event-ring-background-active);
        opacity: var(--opacity-text-low);
      }
    }

    &::before {
      opacity: var(--opacity-ui-mid);
    }
  }
}

.container {
  overflow: hidden;
  padding: 0 6rem 3rem;
  width: 100%;
  position: relative;
  margin: 7rem 0 auto;
}

.time {
  font-size: 10rem;
  line-height: $event-line-height;
  height: $event-line-height;
  overflow: hidden;
  font-weight: $fw-regular;
  font-variant-numeric: tabular-nums;
  margin-left: -1rem; // Counters the float helper below
  margin-top: 1rem;

  // This little float helper means that if the parent is not wide enough to
  // accommodate either the start or the end, it pushes them down and out of the
  // box which hides them (overflow: hidden; handles this) so that when the boxes
  // are too narrow even for the start time, they don't show a half number
  &::before {
    content: "";
    width: 1px;
    height: $event-line-height;
    float: left;
  }
}

.timeStart,
.timeEnd {
  float: left;
  white-space: nowrap;
}

// The numbers in Circular are slightly shorter than their counterpart
// uppercase characters, so this creates the illusion of a matching height
// uppercase character to reduce the dissonance created by their imbalance.
.timeAmPm {
  font-size: 0.9em;
  font-weight: $fw-medium;
  opacity: 0.85;
}

// These are two separate classes rather than an .inline and .inline--small modifier
// to keep the classList easier to manage as they are generated within PeriodView.js
.inlineRegular,
.inlineSmall {
  .container {
    display: flex;
    align-items: center;
    margin-top: auto;
  }

  .containerWithComplete {
    padding-right: 26rem;
  }

  // This wrapper is required to ensure that the truncation is achieved correctly
  // for the label, whilst also being a flexible box to allow the time to show
  // if there is sufficient space
  .description {
    display: flex;
    max-width: 100%;
  }

  .descriptionInner {
    @include line-clamp(1);

    &.small {
      width: 85%;
    }
  }

  .time {
    flex: 1;
    line-height: $event-line-height + 1rem; // Aligns to the baseline
    margin-top: 0;
  }

  .timeStart {
    // Adds a comma between the title and date to break it up,
    // but only when it is inline so it's a pseudo element rather
    // than in the DOM.
    &::before {
      content: ", ";
      margin-right: -0.25em;
      margin-left: -0.1em;
    }
  }
}

.inlineSmall {
  font-size: 10rem;

  .description {
    margin-top: -1rem;
  }

  .time {
    font-size: 8rem;
    margin-top: -1rem;
    line-height: $event-line-height;
  }
}

.stacked {
  .descriptionInner {
    // The duration increment is equal to the number of 15px chunks that
    // an event takes up (up to a maximum of 4). It is subtracted by 2
    // to allow for:
    // 1. The padding above the first line
    // 2. The time to be shown on the last line
    @include line-clamp(calc(var(--duration-increments, 1) - 2));
  }

  .timeWithComplete,
  .descriptionWithComplete {
    padding-right: 20rem;
  }
}

.resizeHandle {
  position: absolute;
  bottom: -1rem;
  left: 0;
  width: 100%;
  height: 3rem;
  opacity: 0;
  cursor: ns-resize;
}

.completionButton {
  position: absolute;
  bottom: 8rem;
  right: 5rem;

  @include focus-ring($selector: "&::after", $border-radius: 50%);

  &.xsmall {
    bottom: 5rem;
    right: 5rem;

    span {
      height: 9rem;
      width: 9rem;
    }
  }
}

.removeFromCalendarButton {
  opacity: 0;
  position: absolute;
  top: 2rem;
  right: 5rem;
  transition: opacity $transition;

  @include focus-ring($selector: "&::after", $border-radius: 50%);

  &.xsmall {
    top: -2rem;
    right: 15rem;
    height: 12px;
    width: 12px;

    svg {
      max-width: 100%;
    }
  }

  &.small {
    top: 5rem;
    right: 25rem;
  }
}
