@import "../../../global";

@mixin create-sidebar-colors($mix-color, $mix-percent) {
  .sidebar {
    @each $color-number, $_ in $category-color-sets {
      $sidebar-background: mix(
        get-category-shade($color-number),
        $mix-color,
        $mix-percent
      );

      --category-sidebar-#{$color-number}: #{$sidebar-background};
    }
  }
}

@include light-theme {
  @include create-sidebar-colors($colors-light-600, 3%);
}

@include dark-theme {
  @include create-sidebar-colors($colors-dark-600, 5%);
}

.tabBar {
  margin-top: $panel-padding;
}
