@import "../../global";

.main {
  font-size: 16rem;
  grid-area: 1 / 3;
  position: relative;
  overflow: hidden;
  z-index: 3;
  padding-top: 48rem;
  @include sidebar-container-animate-out;
}

.expanded {
  .main {
    @include sidebar-container-animate-in;
  }
}

.calendar {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow-y: auto;
  // Visibility is used to ensure that nothing is focusable when the calendar is hidden,
  // but it should only be set to hidden after the opacity transition has happened.
  transition: opacity $duration-state ease-in, visibility 0ms;
  // These competing animations require a bit of extra GPU work so using `will-change`
  // forces these onto their own paint layers.
  will-change: transform;
}

.calendarHidden {
  opacity: 0;
  transition: opacity $duration-state $duration-state ease-out,
    visibility 0ms $duration-state;
  visibility: hidden;
}

.detail {
  width: calc(100% - #{$sidebar-margin * 2});
  height: calc(100% - #{$sidebar-margin * 2});
  top: $sidebar-margin;
  left: $sidebar-margin;
  position: absolute;
  overflow: hidden;
  z-index: 2;
  border-radius: $sidebar-border-radius;
}
