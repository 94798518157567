// Media queries don't play ball with the calc(1em/16) root font-size so having a mixin allows
// us to obfuscate the pixels to rem conversion that differs from the 10rem = 10px implementation
// in the rest of the build
@use "sass:math";

@mixin min-width($min-pixels) {
  @media screen and (min-width: #{math.div($min-pixels, 16)}em) {
    @content;
  }
}

@mixin max-width($max-pixels) {
  @media screen and (max-width: #{math.div($max-pixels - 1, 16)}em) {
    @content;
  }
}

@mixin min-max-width($min-pixels, $max-pixels) {
  @media screen and (min-width: #{math.div($min-pixels, 16)}em) and (max-width: #{math.div($max-pixels - 1, 16)}em) {
    @content;
  }
}

// Matches the media query used in hooks/usePointerHover.js to augment experiences where hover can
// be used. The default should be that things don't require it unless there is a good reason too.
@mixin can-hover {
  @media (hover: hover) and (pointer: fine) {
    @content;
  }
}

@mixin line-clamp($lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;

  // Checking for not 1 rather than greater than one allows for strings to be passed
  // i.e. @include line-clamp(var(--line-clamp))
  @if ($lines != 1) {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lines;

    display: -webkit-box;
  } @else {
    white-space: nowrap;
  }
}

@function ends-with($string, $find) {
  @return str-slice($string, (str-length($string) - str-length($find) + 1)) ==
    $find;
}
