@import "../../../global";

@import "./../DetailPanel.module.scss";

// 860 is the smallest size that they can reasonably be arranged next to each other
$unstack-details: 860;

.buttons {
  :global(.react-datepicker-wrapper) {
    margin-right: auto;
    margin-left: 0;
  }
}

.heroGroup {
  text-align: left;
  width: calc(100% - #{$panel-padding * 2});
  margin-top: $panel-padding * 3;
  max-width: $detail-panel-max-width + $panel-padding * 2;

  @include min-width($unstack-details) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: $panel-padding;
  }

  .label {
    margin-top: 0;
  }

  .title,
  .description {
    margin-top: 4rem;
    margin-bottom: 0;
  }
}

.result,
.purpose {
  border: 2rem solid transparent;
  padding: $panel-padding - 2rem;
}

.result {
  border-radius: 20rem;
  border-color: currentColor;

  .description {
    font-weight: $fw-medium;
  }
}

.purpose {
  padding-bottom: 0;

  @include min-width($unstack-details) {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: $panel-padding - 2rem;
  }
}

.resultHasColor {
  border-color: category-color(default);
}

.header {
  .group {
    display: flex;
    gap: $panel-padding;
    align-items: flex-start;
    flex-wrap: wrap;

    > div {
      width: 100%;

      @include min-width($unstack-details) {
        flex: 1;
      }
    }

    .label {
      margin-top: 2rem; // Optically balances the space
    }

    .description {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

.actions {
  $actions-empty-state-dropzone-height: $action-card-height +
    $card-banner-height + $card-margin;
  --sortable-empty-state-height: #{$actions-empty-state-dropzone-height};
}

.actionsHeader {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.actionsHeading {
  margin-right: 8rem;
  margin-top: 0;
}

.actionsCounters {
  margin-bottom: 12rem;
}

.actionsHidden {
  opacity: 0;
  visibility: hidden;
  height: 0;
  pointer-events: none;
}
