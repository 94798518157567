@use "sass:math";

@import "../../global";

$onboarding-bottom-shim-height: ($panel-padding * 3) +
  ($button-size-regular * 1rem);

.page {
  display: flex;
  flex-direction: column;
  font-size: 16rem;
}

.header,
.main {
  margin: 0 auto;
  max-width: 680rem;
  padding: $panel-padding;
  width: 100%;
}

.header {
  text-align: center;
  font-size: 16rem;
  line-height: 24rem;
  padding-top: $panel-padding-top;
  padding-bottom: 40rem;
}

.title {
  margin-top: 0;
}

.main {
  padding-bottom: $onboarding-bottom-shim-height;

  &::after {
    content: "";
    position: fixed;
    background: linear-gradient(
      theme-color(page-background, 0),
      theme-color(page-background, 0.3) 50%,
      theme-color(page-background)
    );
    height: $onboarding-bottom-shim-height;
    bottom: 0;
    left: 0;
    width: 100%;
    pointer-events: none;
  }
}

.buttons {
  bottom: 0;
  height: $onboarding-bottom-shim-height;
  display: flex;
  left: 50%;
  margin-left: math.div($button-block-width, -2);
  place-items: center;
  position: fixed;
  width: $button-block-width;
  z-index: 2;
}
