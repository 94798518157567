@import "../../global";

.button {
  color: theme-color(200);
  font-size: 13rem;
  font-weight: 400;
  margin-left: auto;
  text-transform: none;

  @include focus-ring(
    $selector: "&::before",
    $border-radius: $button-square-border-radius + 4rem
  );

  // Places a space beetween ChildrenList while still loading
  + div {
    margin-top: 30rem;
  }

  &.active {
    svg {
      color: #{theme-color(yellow-star)};
    }
  }
}
