@mixin focus-ring-el($args...) {
  :global(.js--focus-visible) & {
    left: map-get(keywords($args), left) or $focus-ring-offset;
    right: map-get(keywords($args), right) or $focus-ring-offset;
    top: map-get(keywords($args), top) or $focus-ring-offset;
    bottom: map-get(keywords($args), bottom) or $focus-ring-offset;
    // Deliberately large value to accommodate all radii sizes
    border-radius: map-get(keywords($args), border-radius) or 100rem;
    position: absolute;
    border: $focus-ring-width solid
      var(--focus-ring, #{theme-color(focus-ring)});
    pointer-events: none;
    @content;
  }
}

@mixin focus-ring($args...) {
  $selector: map-get(keywords($args), selector);

  @if ($selector) {
    $pseudo-selector: false;

    // ends-with allows for &::after, but also something like > foo::after
    @if (ends-with($selector, "::after") or ends-with($selector, "::before")) {
      $pseudo-selector: true;
    }

    &:focus-visible {
      #{unquote($selector)} {
        @include focus-ring-el($args...) {
          @if ($pseudo-selector) {
            content: "";
          }
        }
      }
    }
  } @else {
    &:focus-visible {
      @include focus-ring-el($args...);
    }
  }
}
