@use "sass:math";

@import "../../global";

$logo-height: 44rem;

.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  margin: auto;
  max-width: 1200rem;
  width: 100vw;
}

.columns {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 1;

  .authFormWrapper {
    max-width: 400rem;
  }

  .videoWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding-top: clamp(80rem, calc(18vh - 22rem), 150rem);
    margin-bottom: -120rem; // offset position of video to be middle of access form.

    iframe {
      margin-left: auto;
    }
  }
}

.logo {
  height: $logo-height;
  width: math.div(203, 70) * $logo-height; // Values come from logo.svg width/height
  margin: 100rem 0;

  @supports (margin: clamp(1rem, 2rem, 3rem)) {
    margin: clamp(80rem, calc(18vh - #{$logo-height * 0.5}), 160rem) 0;
  }
}

.text {
  font-size: 16rem;
  line-height: 24rem;
  margin-bottom: 32rem;
  font-weight: $fw-medium;
}
