@use "sass:math";

@import "../../global";

.list {
  margin: 0 $item-padding * -1;
}

.item {
  box-sizing: border-box;
  color: theme-color(text);
  display: flex;
  margin-bottom: $item-margin;
  position: relative;
  min-height: $item-height;
  border-radius: $item-border-radius;
  transition: background $transition;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    right: $item-padding;
    width: $item-active-indicator-size;
    height: $item-active-indicator-size;
    background: currentColor;
    border-radius: $item-active-indicator-size * 0.5;
    margin-top: math.div($item-active-indicator-size, -2);
    transition: $transition transform;
    transform: scale(0);
  }

  &.active {
    background: theme-color(text, var(--opacity-ui-lowest));

    &::before {
      transform: scale(1);
    }
  }
}

.summary {
  appearance: none;
  align-items: center;
  position: relative;
  cursor: pointer;
  display: flex;
  text-align: left;
  flex: 1;
  z-index: 1;
  width: 100%;

  @include focus-ring(
    $selector: "&::after",
    $border-radius: $item-border-radius + 3rem
  );
}

.info {
  flex-grow: 1;
  min-width: 0; // Prevents the text inside to expand the element width
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: $item-padding * 1.5 + $item-active-indicator-size;
}

.avatar {
  --avatar-size: 32rem;
  margin: $item-padding;
}

.name {
  @include line-clamp(2);

  margin: 0;
  line-height: $item-line-height;
  flex-shrink: 0;
}
