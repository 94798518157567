@import "../../global";

@import "../CreateDialog/CreateDialog.module.scss";

$leverage-dialog-height: 88rem;
$suggestion-margin: 20rem;

.suggestions {
  width: 100%;
  top: calc(100% + #{$suggestion-margin});
  max-height: calc(
    50vh - #{($leverage-dialog-height * 0.5) + ($suggestion-margin * 2)}
  );
  overflow: auto;
}

.leverageInput {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.removeLeverage {
  flex: 0 0;
  padding: 0 12rem;
  margin-left: 12rem;
  border: 1rem solid theme-color(negative);
  border-radius: 8rem;
  font-size: 14rem;
  font-weight: $fw-regular;
  line-height: 32rem;
  color: theme-color(negative);
  cursor: pointer;

  &:focus-within {
    outline: 2rem solid #{$color-focus-ring};
  }
}

