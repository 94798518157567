@import "../../global";

@import "../CreateDialog/CreateDialog.module.scss";

.autocomplete {
  -webkit-app-region: no-drag;
  color: theme-color(text);
  white-space: pre-wrap;
  user-select: auto; // Safari breaks the contenteditable without this rule
  cursor: text;
  font-variant: no-contextual;
  word-break: break-word;
  font-weight: $create-dialog-input-font-weight;
  position: relative;

  :global(.mention) {
    font-weight: $fw-bold;
    user-select: all;
    cursor: text;
  }

  &:empty::before {
    content: attr(data-placeholder);
    opacity: $input-placeholder-opacity;
    font-weight: $input-placeholder-font-weight;
    pointer-events: none;
  }

  @include focus-ring(
    $selector: "+ .focusRing",
    $top: 2rem,
    $left: 2rem,
    $right: 2rem,
    $bottom: 2rem,
    $border-radius: 28rem
  );
}

.peopleSuggester {
  width: 400rem; // Increased this to accomodate the Create New button and long names
  z-index: 2;
  position: fixed;
}
