@import "../../global";

@import "../CreateDialog/CreateDialog.module.scss";

$list-item-padding: 12rem;

.wrapper {
  position: relative;

  @include focus-ring(
    $selector: ".selectedCurrent::before",
    $border-radius: 12rem
  );
}

.selected {
  display: flex;
  align-items: center;
  font-size: 12rem;
  line-height: 18rem;
}

.selectedWrapper {
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.label {
  margin-right: 8rem; // Design has 4px, but an extra 4 is needed to balance out the space to mimic the design
  margin-bottom: 0;
  white-space: nowrap; // Stops the label being split over multiple lines
}

.selectedCurrent {
  display: flex;
  align-items: center;
  cursor: pointer;
  background: category-color(default);
  color: category-color(text);
  border-radius: 8rem;
  font-size: 13rem;
  padding: 4rem 4rem 4rem 8rem;
  line-height: 16rem;
  font-weight: $fw-medium;
  position: relative;
  flex: 1;

  > span {
    white-space: nowrap;
    overflow: hidden;
    max-width: 280rem;
    text-overflow: ellipsis;
  }

  svg {
    margin-left: 10rem;
    margin-right: 4rem;
    flex-shrink: 0;
  }

  path {
    fill: currentColor;
  }
}

.selectedCurrentCapture {
  background: theme-color(text, var(--opacity-ui-lowest));
  color: theme-color(text);
}

.list {
  background: theme-color(dialog-background);
  color: theme-color(text);
  box-sizing: border-box;
  border-radius: 8rem;
  border: theme-color(border) 1rem solid;
  position: absolute;
  top: 50%;
  // Half the height of a .listItem plus the 1rem border of the wrapper to vertically center the
  // list over the currentlySelected item
  margin-top: -22rem;
  left: $list-item-padding * -1;
  // Ensures that it has a gutter against the bottom when used in ActionDialog. If this is ever abstracted
  // to be used elsewhere, this should be swapped out for a configurable CSS Var
  max-height: calc(50vh - 24rem);
  overflow: auto;
  width: max-content;
  min-width: 300rem;
  max-width: 500rem;
  z-index: 1;

  &.hidden {
    visibility: hidden;
  }
}

.listGroup {
  display: block;
  box-sizing: border-box;

  + .listGroup {
    border-top: theme-color(border) 1rem solid;
  }

  &:last-child {
    .listItem {
      &:last-child {
        // When scrolling to the last item the extra padding ensures that they line up properly
        padding-bottom: $list-item-padding + 2rem;
      }
    }
  }
}

.listItem {
  color: category-color(default);
  display: flex;
  align-items: center;
  padding: $list-item-padding;
  padding-top: $list-item-padding - 1rem;
  font-weight: $fw-medium;

  path {
    fill: theme-color(text);
  }

  &[aria-selected] {
    // Hover states can only be applied to selectable items and [aria-selected="true/false"] allows that to
    // be easily managed without an extra class
    &.hover {
      background: theme-color(text, var(--opacity-ui-lowest));

      .listCheck {
        opacity: 0.25;
      }
    }
  }

  &[aria-selected="true"] {
    .listCheck {
      opacity: 1 !important;

      path {
        fill: category-color(default) !important;
      }
    }
  }
}

.blocks {
  .listItem {
    color: theme-color(text);
    position: relative;
    font-weight: $fw-medium;
    padding-top: $list-item-padding;

    > span {
      @include line-clamp(2);
    }

    &::before {
      content: "";
      background: theme-color(border);
      height: 1px;
      position: absolute;
      left: 24rem;
      right: 0;
      top: 0;
    }
  }
}

.blockIcon {
  border-radius: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16rem;
  width: 16rem;
  margin-right: 8rem;
  margin-left: 16rem;
  flex-shrink: 0;

  path {
    fill: category-color(default);
  }
}

.blockSnoozed {
  opacity: 0.5;
  margin: 0 16rem 0 8rem;

  path {
    fill: currentColor;
  }
}

.listCheck {
  margin-left: auto;
  opacity: 0;
  flex-shrink: 0;
}
