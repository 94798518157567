@import "../../global";

$badge-color: #FF5F55;
$badge-font-size: 10rem;

.counterBadge {
    position: absolute;
    top: -8rem;
    right: -8rem;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18rem;
    height: 18rem;
    padding: 0;
    margin: 0;
    border-radius: 50%;
    background-color: $badge-color;

    .counter {
        color: $body-color;
        font-size: $badge-font-size;
        letter-spacing: -0.02em;
        line-height: 32px;
        font-weight: $fw-medium;
        text-align: center;
    }
}