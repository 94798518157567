@use "sass:math";

@import "../../global";

$input-height: 3em;
$input-padding: var(--input-padding);
$input-total-padding-height: "(#{$input-padding} * 2)";
$input-line-height: "(#{$input-height} - #{$input-total-padding-height})";

$input-label-filled-modifier: math.div(15, 18); // Turns a 18px label into 15px

// Hack to ensure that it fills the width of the parent always, Safari doesn't
// allow 100vw (it flashes the render) so we have to use an arbitrary value
// that is higher than any instances of its use within the app. To ensure that
// it doesn't break the functionality, the max-width is also applied to the
// parent, so if instances do occur that are greater than this number (which
// is highly unlikely), simply bump this number up to the maximum width of the
// new instance.
// Note – The largest current instance is in Detal Panel forms at 920px.
$image-input-max-width: 1000rem;

.field {
  --input-padding: 14rem;

  font-size: $input-font-size-regular;
  line-height: 1.5;
  margin-bottom: 2.5em;
  position: relative;
  display: block;
  text-align: left;

  &.small {
    --input-padding: 4rem;
  }
}

.fieldHasCounter {
  input,
  textarea {
    padding-right: calc(
      #{$character-counter-size} + var(--input-padding, 8rem)
    );
  }

  .counter {
    position: absolute;
    right: 0;
    top: calc(#{$input-height * 0.5} - #{$character-counter-size * 0.5});
  }
}

.label {
  margin-bottom: 8rem;
  margin-top: 0;
  opacity: var(--opacity-text-mid);
}

.inputWrapper {
  position: relative;

  &::after {
    box-sizing: border-box;
    content: "";
    position: absolute;
    bottom: 0;
    height: 1rem;
    width: 100%;
    pointer-events: none;
    background: currentColor;
    opacity: var(--opacity-ui-mid);
    transition: $transition;
    transition-property: background-color, opacity;
  }

  &:focus-within {
    &::after {
      background-color: var(--input-border-color-focus, #{theme-color(accent)});
      opacity: 1;
    }
  }

  .fieldReadonly & {
    &::after {
      background: currentColor;
      opacity: var(--opacity-ui-mid);
    }
  }
}

.inputInput {
  appearance: none;
  padding: $input-padding 0;
  border-radius: inherit;
  display: block;
  font-weight: $fw-medium;
  font-size: 1em;
  line-height: calc(#{$input-line-height});
  height: $input-height;
  width: 100%;
  position: relative;

  &::placeholder {
    color: currentColor;
    opacity: var(--opacity-text-low);
    font-weight: $input-placeholder-font-weight;
  }

  @include focus-ring(
    $selector: "+ .focusRing",
    $border-radius: var(--input-focus-ring-border-radius, 4rem),
    $left: -6rem,
    $right: -6rem
  );

  .fieldReadonly & {
    opacity: var(--opacity-text-mid);
  }
}

.inputWrapper {
  textarea {
    resize: none;
    max-height: calc(
      #{unquote($input-line-height)} * var(--textarea-max-rows, 3) + #{unquote(
          $input-total-padding-height
        )}
    );
    min-height: calc(
      #{unquote($input-line-height)} * var(--textarea-min-rows, 2) + #{unquote(
          $input-total-padding-height
        )}
    );
  }

  select {
    padding-right: calc(#{$select-arrow-height} + 4rem);
    text-overflow: ellipsis;
  }

  [type="file"] {
    &::-webkit-file-upload-button {
      display: none;
    }
  }
}

.arrow {
  position: absolute;
  top: 50%;
  right: 0;
  margin: math.div($select-arrow-height, -2) + 1rem 0 0; // 1rem opticall centers the arrow

  path {
    fill: currentColor;
  }
}

.small {
  // Note that smaller than 16px will force Safari to zoom on mobile
  font-size: $input-font-size-small;

  &.fauxFilled {
    font-size: $input-font-size-small * $input-label-filled-modifier;
  }
}

.regular {
  font-size: $input-font-size-regular;

  &.fauxFilled {
    font-size: $input-font-size-regular * $input-label-filled-modifier;
  }
}

.inlineLabel {
  display: flex;
  align-items: center;

  .label {
    margin-right: 8rem;
    margin-top: 8rem;
    margin: 0;
  }

  .inputWrapper {
    flex-shrink: 0;
  }
}

$regular-label-filled-height: 1em;

.regularLabel {
  padding-top: $regular-label-filled-height;

  .label {
    @include line-clamp(1);

    margin: 0;
    font-size: 1em * $input-label-filled-modifier;
    position: absolute;
    line-height: $input-height;
    transition: transform $transition;
    transform: scale(#{math.div(1, $input-label-filled-modifier)}) translateY(0);
    transform-origin: left top;
    width: 100% * $input-label-filled-modifier;
  }

  // See corresponding logic in Input.js for explanation
  &.mounting {
    .label {
      transition: none;
    }
  }

  .focusRing {
    top: calc((#{$regular-label-filled-height} + 4rem) * -1) !important;
  }

  &.filled,
  &:focus-within {
    .label {
      transform: scale(1)
        translateY(math.div($input-height, -1) + $regular-label-filled-height);
    }
  }
}

.fieldVisuallyHidden {
  padding-top: 0;
  margin-bottom: 0;

  .inputWrapper {
    &::after {
      display: none;
    }
  }

  .focusRing {
    top: -4rem !important;
    left: -4rem !important;
    right: -4rem !important;
    bottom: -4rem !important;
  }
}

// Magic number equal to the size of the button with roughly the correct padding
// to allow for the padding on the placeholder
$image-button-width: 88rem;

.imageButtonOuter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  // .Button element
  > label {
    // Input elements use this by default so it overrides Button
    cursor: default;
    padding: 0;

    // .Button_children element
    > span {
      width: $image-button-width;
    }
  }
}

.imageButtonInner {
  // Needed to stop Safari ignoring the transition
  transform: translate3d(0, 0, 0);
  width: $image-input-max-width;
  position: absolute;
  top: 50%;
  margin-top: math.div($input-height, -2);
  right: 0;
  height: $input-height;
}

.imageInput {
  padding-top: 0;
  max-width: $image-input-max-width;

  input {
    opacity: 0;
  }
}

.imagePlaceholder {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-weight: $input-placeholder-font-weight;
  opacity: var(--opacity-text-low);
  padding-right: calc(#{$image-button-width} + var(--input-padding, 8rem));

  span {
    @include line-clamp(1);
  }
}

.imageLabel {
  align-items: flex-start;
  display: flex;

  > span {
    margin-bottom: 0;
  }

  > button {
    flex-shrink: 0;
    margin-left: 8rem;
    margin-top: -2rem;
    margin-bottom: -4rem;
  }
}

.imagePreview {
  position: relative;
  background: var(--image-preview-background, theme-color(dialog-background));
  overflow: hidden;
  height: var(--image-preview-height, 200rem);
  width: var(--image-preview-width, 200rem);
  margin-bottom: 16rem;
  margin-top: 10rem;

  img {
    object-fit: var(--image-preview-object-fit, contain);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
