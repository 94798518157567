@import "../../../global";

$item-height: 42rem;
$item-icon-margin: 10rem;
$item-icon-size: 16rem;

.wrapper {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.list {
  flex-grow: 1;
  font-weight: $fw-regular;
  overflow-y: auto;

  &.empty {
    padding: $dialog-padding;
    display: grid;
    align-items: center;
  }

  @include focus-ring(
    $selector: "+ .focusRing",
    $border-radius: 0rem,
    $top: 0rem,
    $right: 0rem,
    $left: 0rem,
    $bottom: 0rem
  );

  .wrapper:last-child & {
    + .focusRing {
      border-bottom-left-radius: $dialog-border-radius - $dialog-border-width !important;
      border-bottom-right-radius: $dialog-border-radius - $dialog-border-width !important;
    }
  }
}

.category {
  @include line-clamp(2);

  padding: 0 $dialog-padding;
  display: flex;
  align-items: center;
  position: relative;
  cursor: default;
  min-height: $item-height;
  margin: 0;
  opacity: 1;

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: $dialog-padding;
    right: $dialog-padding;
    background: category-color(default);
    height: 3rem;
  }

  + .category {
    margin-top: 8rem;
  }
}

.item {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 13rem;
  line-height: 16rem;
  padding: 0 $dialog-padding;
  min-height: $item-height;

  path {
    fill: currentColor;
  }

  &.current {
    background: theme-color(text, var(--opacity-ui-lowest));
  }
}

.star {
  color: transparent;
  flex: 0 0 auto;
  margin: 0 $item-icon-margin 0 0;
}

.starActive {
  color: category-color(default);
}

.icon {
  flex: 0 0 auto;
  margin: 0 $item-icon-margin 0 0;
  color: category-color(default);

  .action & {
    color: theme-color(text, var(--opacity-text-low));
  }
}

.label {
  @include line-clamp(2);

  margin-right: 9rem;
}

.time {
  flex: 0 0 auto;
  font-size: 10rem;
  font-weight: $fw-bold;
  opacity: var(--opacity-text-low);
  margin-right: 9rem;
}

.tick,
.next {
  flex: 0 0 auto;
  margin-left: auto;
  color: theme-color(text, var(--opacity-text-low));
}

.tick {
  opacity: 0;

  .current & {
    opacity: var(--opacity-ui-mid);
  }

  &.selected {
    opacity: 1;
    color: category-color(default);
  }
}

.divider {
  margin: 0;
  margin-right: $dialog-padding;
  margin-left: $dialog-padding + $item-icon-size + $item-icon-margin;
  width: auto;
}
