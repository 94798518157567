@import "../../global";
@import "../DragAndDrop/Sortable.module.scss";

.loading {
  position: relative;
}

.heading {
  color: theme-color(200);
  font-size: 13rem;
  margin-bottom: 0;
  letter-spacing: 0.025em;
  padding: 0 $sidebar-heading-padding;
  text-transform: uppercase;
}

.emptyActionList {
  margin-top: 0;
}
