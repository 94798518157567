@import "../../../global";

.dialogHeaderCategory {
  background: category-color(default);
  border-color: category-color(default);
  color: category-color(text);
}

.dialogBody {
  display: flex;
  flex-direction: column;
  padding: 15rem - 1rem; // Accounts for the border of the dialog
  overflow-y: auto;
  flex: 1;

  // This creates a graded gradient mask rather than using pseudo elements overlaid
  // over the top which reduces the need to maintain the background colour of the
  // gradients.
  -webkit-mask: linear-gradient(
    rgba(black, 0) 1rem,
    rgba(black, 0.33) #{16rem * 0.25},
    rgba(black, 0.66) #{16rem * 0.5},
    black #{16rem},
    black calc(100% - #{16rem}),
    rgba(black, 0.66) calc(100% - #{16rem * 0.5}),
    rgba(black, 0.33) calc(100% - #{16rem * 0.25}),
    rgba(black, 0) calc(100% - 1rem)
  );
}
