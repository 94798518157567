@import "../../global";
@import "../Card/Card.module.scss";

$block-card-bottom-padding: $card-bottom-padding + ($button-size-small * 1rem) + $card-buttons-margin;
$block-card-result-margin: 1rem;

$block-card-column-size: 32rem;
$block-card-icon-size: 16rem;

$block-card-title-width: 176rem;

$block-card-expand-button-height: 24rem;

// Must be above the rest of the content to register visually, but
// pointer-events is okay to be added as the DND library ignores this.
.dropzone {
  z-index: 3;
  pointer-events: none;
}

.card {
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-bottom: $card-bottom-padding;
  cursor: pointer;


  @include focus-ring(
    $selector: "&::after",
    $bottom: 0,
    $left: 0,
    $top: 0,
    $right: 0,
    $border-radius: 8rem,
  );
  
  .actionsList {
    position: relative;
    display: block;
    font-size: 13rem;
    padding: 0 $card-padding;
    overflow: hidden;
    max-height: 0;
  } 

  .actionsList.open {
    max-height: none;
  }
}

.content {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto;

  padding: $card-padding;

  --inset-box-shadow: #{category-color(dark)};

  background: category-color(default)
    linear-gradient(
      // Designs are 15%, but has been reduced to 10% for a11y
      category-color(text, 0.1),
      category-color(text, 0.05),
      category-color(text, 0)
    );
  color: category-color(text);

  .completionWrapper {
    grid-row: 1;
    grid-column: 1;
    display: flex;
    flex: 0 1 auto;
    justify-content: center;
  }

  .info {
    grid-row: 1;
    grid-column: 2;
    display: flex;
    flex: 0 1 auto;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .blockButtons {
    grid-row: 1;
    grid-column: 3;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: nowrap;
  }

  .starredBlockFavoriteButton {
    background-color: #fff;
    --button-color: #{theme-color(yellow-star)};

    @include focus-ring(
      $selector: "&::before",
      $border-radius: 8rem,
      $bottom: -4rem,
      $left: -4rem,
      $top: -4rem,
      $right: -4rem,
    );
  }
}

.expandButton {
  @include focus-ring(
    $selector: "&::before",
    $border-radius: 8rem,
    $bottom: -4rem,
    $left: -4rem,
    $top: -4rem,
    $right: -4rem,
  );
}

.optionsBar {
  padding: $card-padding $card-padding calc($card-padding / 2) $card-padding;
}

.options {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  min-height: $block-card-expand-button-height;

  & > span {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    padding-right: 18rem;
  }

  @media (min-width: 1536px) {
    & > span {
      padding-right: 64rem;
    }
  }

  .counterGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
  }

  .counterSpace {
    min-width: 38rem;
    height: 16rem;
    margin-left: 6rem;
  }

  .loader {
    position: relative;
    width: 100%;
  }
}

.label {
  flex: 0 1 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.result {
  font-weight: $fw-regular;
  font-size: $card-title-font-size;
  line-height: $card-line-height;
  display: flex;
  align-items: center;
  margin-bottom: $block-card-result-margin;
  word-break: break-word;

  max-height: $card-line-height * 3 + $card-line-height * 0.5;

  span {
    @include line-clamp(3);
  }
}

.labelIcon {
  width: $block-card-icon-size;
  height: $block-card-icon-size;
}

.dateTimeLabel {
  font-weight: $fw-regular;
  font-size: $card-font-size;
  line-height: $card-line-height;
  color: theme-color(200);
  line-height: $card-line-height;
  word-break: break-word;
  margin: 4rem 0;
}

.completionButton {
  display: flex;
  cursor: pointer;
  width: $action-item-completion-button-width;
  position: relative;
  top: 0;
  right: 0;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding-bottom: 3rem; // Optically balances the shadow
  transition: padding-top $transition;

  // Allows the .completionButton to underlap the banner so the border radius
  // is maintained, but ensures that the position of the checkbox updates in
  // order to keep its vertical alignment centred.
  .banner[aria-hidden="false"] ~ & {
    padding-top: $card-banner-height;
  }

  @include focus-ring(
    $selector: "&::after",
    $border-radius: $card-border-radius + 0rem 2rem 2rem 0rem,
    $left: -12rem,
    $top: -12rem,
    $bottom: -12rem,
    $right: 2rem
  );

  &[disabled] {
    cursor: default;
    opacity: $button-disabled-opacity;
  }
}

@include light-theme {
  .labelIcon {
    path {
      fill: theme-color(100);
    }
  }
}

@include dark-theme {
  .labelIcon {
    path {
      fill: category-color(text);
    }
  }
}

.cardWithColor {
  .labelIcon {
    path {
      fill: category-color(text);
    }
  }

  .dateTimeLabel {
    color: category-color(text);
  }
}

.counters {
  margin-top: -1rem; // Offsets icon height
  margin-bottom: $card-buttons-margin - 2rem; // Offsets icon height
  margin-left: -2rem; // Offsets overlap from the star
  width: 100%;
}

.buttonDueDate {
  &:disabled {
    // We don't want this button to be grayed out when disabled, override base style
    opacity: 1 !important;
  }

  svg {
    color: currentColor;
  }
}

.buttonDueDateLabel {
  transition: opacity $transition;
  font-weight: $fw-medium;
  color: currentColor;

  .card:not(.cardWithColor) & {
    opacity: var(--opacity-text-mid);
  }
}

.negative {
  color: theme-color(negative);
}