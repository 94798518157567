@mixin light-theme {
  [data-color-preference="auto"] {
    @content;
  }

  [data-color-preference="light"] {
    @content;
  }
}

@mixin dark-theme {
  [data-color-preference="auto"] {
    @media (prefers-color-scheme: dark) {
      @content;
    }
  }

  [data-color-preference="dark"] {
    @content;
  }
}

/**
 * @param {Color} $color Any valid Scss color will be converted to rgb here
 * @returns {List} A 3 part list equivalent to the r, g, b values of rgb()
 */
@function hex-to-rgb($color) {
  @return "#{round(red($color))}, "+"#{round(green($color))}, "+ "#{round(blue($color))}";
}

// This gives overrides for all --opacity- variables and the focus ring so that
// there is a consistent use of opacity within categories without needing to
// calculate which colours go to what in which instance.
@mixin category-opacity-var-override {
  --opacity-text-low: 0.75;
  --opacity-text-mid: 1;

  --opacity-ui-lowest: 0.1;
  --opacity-ui-low: 0.3;
  --opacity-ui-mid: 0.5;

  --focus-ring: #{category-color(text)};
}

/**
 * @param {String} $color-key A key from the $colors-light or $colors-dark
 * @param {Number} $alpha=1 The alpha channel for the color
 * @returns {Color} An rgba value with the CSS Var and the chosen alpha
 */
@function theme-color($color-key, $alpha: 1) {
  @if (not map-get($colors-light, $color-key)) {
    @error 'Light color missing for #{$color-key}. Ensure it is added, and that ' +
      'you are using the correct key.';
  }
  @if (not map-get($colors-dark, $color-key)) {
    @error 'Dark color missing for #{$color-key}. Ensure it is added, and that ' +
      'you are using the correct key.';
  }

  $color-var: var(--color-#{$color-key});

  @return rgba($color-var, $alpha);
}

/**
 * @param {String} $color-key A key from $category-color-keys or
 *                            $category-color-shades
 * @param {Number} [$alpha=1] The alpha channel for the color
 * @param {String|Color} [$fallback-color] A fallback color
 * @returns {Color} A hex/rgba color depending on which Scss comptues
*/
@function category-color($color-key, $alpha: 1, $fallback-color: false) {
  @if (
    not
      index($category-color-keys, $color-key) and not
      index($category-color-shades, $color-key)
  ) {
    @error 'Invalid key #{$color-key}. Use a key from $category-color-keys or ' +
      '$category-color-shades';
  }

  // Category colors were at one time either dark/light (determined by
  // whichever had better contrast against the category background), but are
  // now just white. To keep the ability to revert this, or to make a global
  // change for how category colors work, this function was kept but for now
  // it only ever returns white.

  // Note that if $alpha is one, these will be returned as hex values, as Scss
  // converts rgba(x, 1) to hex.
  @if ($color-key == "text") {
    @return rgba(white, $alpha);
  }
  @if ($color-key == "text-reversed") {
    @return rgba(black, $alpha);
  }

  // Normalise the fallback color from a valid Scss color into an rgb
  // triplet, or use the default category fallback if none is supplied
  @if (type-of($fallback-color) == color) {
    $fallback-color: hex-to-rgb($fallback-color);
  } @else if (not $fallback-color) {
    $fallback-color: var(--color-cat-fallback);
  }

  // The category colors get a shortened prefix as there are a few of them for
  // every instance of the category colors.
  $color-var: var(--category-#{$color-key}, #{$fallback-color});

  @return rgba($color-var, $alpha);
}

/**
 * @param {String} $color-number The category color name
 * @param {String} [$color-key=default] A key from $category-color-shades
 * @returns {Color} Hex color for the chosen key
 */
@function get-category-shade($color-number, $color-key: default) {
  @if (not index($category-color-shades, $color-key)) {
    @error 'Invalid key #{$color-key}. Use a key from $category-color-shades.';
  }

  $color-set: map-get($category-color-sets, $color-number);
  $color: map-get($color-set, $color-key);

  @if (not $color) {
    $default-color: map-get($color-set, default);

    @if ($color-key == "dark") {
      $color: scale-color($default-color, $lightness: -10%, $saturation: -10%);
    }

    @if ($color-key == "darker") {
      $color: scale-color($default-color, $lightness: -35%, $saturation: -20%);
    }

    @if ($color-key == "light") {
      $color: scale-color($default-color, $lightness: 40%);
    }
  }

  @return $color;
}
