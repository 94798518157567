@use "sass:math";

@import "../../global";

@value transitionDuration: #{math.div($duration, 1ms)};

.toast {
  box-sizing: content-box; // Needed to make Safari respect the max-content
  background: theme-color(100);
  color: theme-color(600);
  border-radius: 8rem 8rem 0 0;
  position: fixed;
  font-weight: $fw-medium;
  line-height: 18rem;
  font-size: 14rem;
  transform: translateX(-50%);
  left: 50%;
  bottom: 0;
  padding: 8rem 16rem;
  display: flex;
  align-items: center;
  min-height: 34rem;
  max-width: 90%;
  max-width: clamp(0px, 90%, 400rem);
  width: max-content;
  text-align: left;
  z-index: $dialog-z-index - 1;
  transition: transform $transition;

  &[hidden] {
    transform: translateX(-50%) translateY(100%);
  }

  @at-root {
    button.toast {
      cursor: pointer;

      @include focus-ring(
        $selector: "&::after",
        $top: 2rem,
        $left: 2rem,
        $right: 2rem,
        $bottom: 2rem,
        $border-radius: 6rem 6rem 2rem 2rem
      );
    }
  }

  strong {
    font-weight: $fw-bold;
  }

  svg {
    display: block;
    flex-shrink: 0;
    margin-right: 10rem;
    margin-left: -2rem;
    margin-top: 1rem;
  }

  path {
    fill: currentColor;
  }

  &:global(.slide-toast-appear),
  &:global(.slide-toast-enter) {
    transform: translate(-50%, 100%);
  }

  &:global(.slide-toast-appear-active),
  &:global(.slide-toast-enter-active) {
    transition: transform $transition;
    transform: translate(-50%, 0);
  }

  &:global(.slide-toast-exit) {
    transform: translate(-50%, 0);
  }

  &:global(.slide-toast-exit-active) {
    transition: transform $transition;
    transform: translate(-50%, 100%);
  }
}

.error {
  background: theme-color(negative);
}

.category {
  @include category-opacity-var-override;

  background: category-color(default);
  color: category-color(text);
}

.dialog {
  z-index: $dialog-z-index + 1;
}

.local {
  position: absolute;
}
