@import "../../global";

.page {
  --page-height: calc(100vh - #{$nav-height});

  background: theme-color(page-background);
  box-shadow: 0 0 12rem rgba(black, 0.45);
  color: theme-color(text);
  display: grid;
  grid-template-columns: auto 1fr;
  position: relative;
  height: var(--page-height);
  overflow-x: hidden;
  overflow-y: auto;
  transition: padding-right $transition-capture-list-out;

  &.sidebarNav {
    grid-template-columns: auto auto 1fr;
  }
}

.pageNoNav {
  --page-height: 100vh;
}

.main {
  height: var(--page-height);
  font-size: 16rem;
  overflow-y: auto;
  padding: $panel-padding-page;
  padding-top: $panel-padding-top;
}

.mainSmoothScroll {
  scroll-behavior: smooth;

  [id] {
    scroll-margin-top: $panel-padding-top;
  }
}
