@import "../../global";

.sidebarNav {
  background: rgba(var(--color-detail-background), 1);
  grid-area: 1 / 1;
  position: relative;
  z-index: 3;

  button {
    color: inherit;
    margin-right: 0;

    & + button {
      margin-top: 14rem;
    }

    // Override NavItem styles
    & > [data-active="true"] {
      background: theme-color(100, $nav-item-active-alpha);
    }
  }
}

.scrollable {
  display: flex;
  flex-direction: column;
  height: var(--page-height);
  padding: 10rem;
  overflow-x: hidden;
  overflow-y: auto;
}

.sidebarCollapse {
  background: theme-color(200);
  border-top-right-radius: $sidebar-collapse-radius;
  border-bottom-right-radius: $sidebar-collapse-radius;
  cursor: pointer;
  height: 20%;
  opacity: 0.4;
  position: absolute;
  right: -8rem;
  top: 30%;
  width: 8rem;
  transition: opacity $transition;

  &:hover {
    opacity: 0.6;
  }

  &.expanded {
    opacity: 0;
  }
}

.divider {
  flex-shrink: 0;
}

.createButton {
  background: theme-color(600);
  color: theme-color(200);
}
