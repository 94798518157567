@import "../../global";

.cardContainer {
  border-radius: $card-border-radius;
  margin: 0 0 $card-margin;
  position: relative;
}

.card {
  --inset-box-shadow: #{theme-color(card-bottom-shadow)};

  background: theme-color(card-background);
  border-radius: $card-border-radius;
  box-shadow: $inset-box-shadow;
  color: theme-color(text);
  position: relative;
  transition: $transition;
  transition-property: background, color, box-shadow;
}

.cardWithColor {
  --inset-box-shadow: #{category-color(dark)};

  background: category-color(default)
    linear-gradient(
      // Designs are 15%, but has been reduced to 10% for a11y
      category-color(text, 0.1),
      category-color(text, 0.05),
      category-color(text, 0)
    );
  color: category-color(text);
}

@mixin create-banner-background($mix-color, $mix-percent) {
  .banner {
    @each $color-number, $_ in $category-color-sets {
      $sidebar-background: mix(
        get-category-shade($color-number),
        $mix-color,
        $mix-percent
      );

      --category-banner-#{$color-number}: #{$sidebar-background};
    }
  }
}

@include light-theme {
  @include create-banner-background($colors-light-600, 20%);
}

@include dark-theme {
  @include create-banner-background($colors-dark-600, 30%);
}

.banner {
  border-radius: $card-border-radius $card-border-radius 0 0;
  height: $card-banner-height;
  display: block;
  font-size: 10rem;
  font-weight: $fw-bold;
  width: 100%;
  padding: 0 $card-padding;
  overflow: hidden;
  text-transform: uppercase;
  transition: $transition;
  transition-property: background, height, margin;

  @each $color-number, $_ in $category-color-sets {
    [data-category-color="#{$color-number}"] & {
      background: var(--category-banner-#{$color-number});
    }
  }

  .cardWithColor & {
    background: category-color(darker);
  }

  > span {
    @include line-clamp(1);

    display: block;
    line-height: $card-banner-height;
  }

  &[aria-hidden="true"] {
    height: 0;
  }
}

.label {
  @include focus-ring(
    $selector: "&::after",
    $border-radius: $item-border-radius + 4rem
  );
}

button.label,
a.label {
  appearance: none;
  cursor: pointer;
}

.buttons {
  --focus-ring: #{theme-color(focus-ring)};

  display: flex;
  align-items: center;

  .cardWithColor & {
    @include category-opacity-var-override;
  }

  > * {
    margin: 0;
    margin-right: $card-buttons-margin;
  }
}

.button {
  --button-after-opacity: var(--opacity-ui-low);

  --button-after-opacity-hover: 1;
  --button-background-hover: transparent;
}

.buttonActive,
.buttonCategory {
  --button-after-opacity: 1;
  --button-border: transparent;
  --button-border-hover: transparent;
}

.buttonActive {
  --button-background: #{theme-color(text)};
  --button-color: #{theme-color(card-background)};

  --button-background-hover: #{theme-color(text)};
  --button-color-hover: #{theme-color(card-background, var(--opacity-text-low))};
}

.buttonActiveWithColor {
  --button-background: #{category-color(text)};
  --button-color: #{category-color(text-reversed)};

  --button-background-hover: #{category-color(text)};
  --button-color-hover: #{category-color(text-reversed, var(--opacity-text-low))};
}

.buttonCategory {
  --button-background: #{category-color(default)};
  --button-color: #{category-color(text)};

  --button-background-hover: #{category-color(default)};
  --button-color-hover: #{category-color(text, var(--opacity-text-low))};
}

.selectButton {
  padding: 0;
  align-items: stretch;

  select {
    // These reverts are required to ensure that Windows machines don't show it as white text
    // when .cardWithColor is present, it also resets the font-weight
    color: revert;
    font-weight: revert;
    cursor: pointer;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @include focus-ring(
      $selector: "+ .focusRing",
      $border-radius: $button-square-border-radius + 4rem
    );
  }

  .selectArrow {
    margin: 1rem 6rem 0 0;
    width: $select-arrow-width;
    height: $select-arrow-height;
  }

  .label {
    min-width: 2.75em;
    text-align: center;
    margin-left: 3rem;
    margin-top: 1rem;
    margin-right: 3rem;
  }
}
