@import "./../../global";

.icons {
  display: grid;
  grid-template-columns: repeat(auto-fill, 48rem);
  grid-gap: 8rem;
}

.icon {
  color: theme-color(200);
  display: grid;
  position: relative;
  border-radius: 50%;
  width: 48rem;
  height: 48rem;

  > * {
    grid-area: 1 / 1;
  }

  svg {
    margin: auto;
    width: 50%;
    height: 50%;
  }

  path {
    fill: currentColor;
  }

  input {
    appearance: none;
    height: 100%;
    width: 100%;
    opacity: 0;
    cursor: pointer;

    @include focus-ring($selector: "+ .focusRing", $border-radius: 50%);
  }
}

.iconSelected {
  background: category-color(default);
  color: category-color(text);
}
