@import "./Card.module";

.outline {
  --button-border: transparent;
  --button-after-opacity: var(--opacity-ui-mid);
  --button-color: currentColor;

  --button-border-hover: transparent;
  --button-background-hover: transparent;
  --button-color-hover: #{theme-color(yellow-star)};
  --button-after-opacity-hover: var(--opacity-ui-mid);

  --button-icon-size: 15rem;

  @include focus-ring(
    $selector: "&::before",
    $bottom: -4rem,
    $left: -4rem,
    $top: -4rem,
    $right: -4rem,
    $border-radius: 6rem,
  );
}

.buttonActive {
  --button-background: transparent;
  --button-color: #{theme-color(yellow-star)};

  --button-background-hover: transparent;
  --button-color-hover: #{theme-color(yellow-star, var(--opacity-text-low))};

  --button-icon-size: 15rem;
}