@import "../../global";

.error {
  margin-top: 50rem;
  width: 100%;

  pre {
    font-family: monospace;
    margin-top: 10rem;
    font-size: 12rem;
  }
}
