@import "../../global";

.navItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 72rem;
  height: 54rem;
  font-size: $nav-item-font-size;
  border-radius: $nav-item-radius;
  cursor: pointer;
  opacity: 0.8;
  gap: 3rem;
  transition: opacity, background $transition;

  &:hover {
    opacity: 1;
  }

  &.active {
    opacity: 1;
    background: rgba($colors-light-600, $nav-item-active-alpha);
  }

  svg {
    margin-bottom: 2rem;
    height: 24px;
    width: 24px;
  }

  span {
    max-width: 65rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
