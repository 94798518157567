@use "sass:math";

@import "../../global";

$logo-height: 44rem;

.page {
  // PageNoUi doesn't use dark/light mode settings, but inherits them from html
  // so we need to override the accent colour here to always be the light one
  --color-accent: #{hex-to-rgb($color-accent-light)};

  background: linear-gradient(
    rgba($body-background, 0),
    rgba($body-background, 0.8)
  );
  display: grid;
  grid-template-columns: 1fr auto 3fr;
  position: relative;
  height: 100%;
  overflow-y: auto;
  font-size: 14rem;
  line-height: 18rem;
}

.wrapper {
  grid-column: 2;
  position: relative;
  margin: auto;
  max-width: 460rem;
  width: 100vw;
  padding: 0 $panel-padding $panel-padding * 3;
  height: 100%;
}

.logo {
  height: $logo-height;
  width: math.div(203, 70) * $logo-height; // Values come from logo.svg width/height
  margin: 100rem 0;

  @supports (margin: clamp(1rem, 2rem, 3rem)) {
    margin: clamp(80rem, calc(18vh - #{$logo-height * 0.5}), 160rem) 0;
  }
}

.heading {
  margin-top: 0;
}

.text {
  font-size: 16rem;
  line-height: 24rem;
  margin-bottom: 32rem;
  font-weight: $fw-medium;
}
