@import "../../global";

.nav {
  color: $body-color;
  top: 0;
  left: 0;
  width: 100%;
  height: $nav-height;
  padding: 0 $panel-padding;
  z-index: $dialog-z-index - 1;
  display: flex;
  align-items: center;
  background: $nav-background;
}

.title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-right: auto;
}

.marque {
  display: block;
  margin-right: 12rem;
  transition: filter $transition;
  position: relative;

  // Makes the logo white on hover (fills won't transition) as an <img />
  // was preferred over an <svg> due to the gradient fills being difficult
  // to transition.
  &:hover {
    filter: saturate(0) brightness(3.5);
  }

  @include focus-ring(
    $selector: "&::before",
    $border-radius: 50%,
    $top: -6rem,
    $right: -6rem,
    $bottom: -6rem,
    $left: -6rem
  );

  > img {
    display: block;
    height: 32rem;
    width: 32rem;
  }
}

.heading {
  line-height: 32rem;
  font-size: 24rem;
  font-weight: $fw-bold;
  text-transform: uppercase;
}
