@import "../../global";

.heading {
  display: block;
  font-weight: $fw-medium;
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.h0,
.h1,
.h2 {
  font-family: $ff-heading;
  font-weight: $fw-heading;
  text-transform: uppercase;
}

.h0 {
  font-size: 64rem;
  line-height: $heading-level-0-line-height;
  margin-bottom: 0.3em;

  @include min-width($heading-level-0-large-font-size-breakpoint) {
    font-size: 80rem;
  }
}

.h1 {
  font-size: 48rem;
  line-height: 42rem;
}

.h2 {
  font-size: 34rem;
  line-height: 38rem; // slight increase for emoji characters line height consideration.
}

.h3 {
  font-size: $heading-level-3-font-size;
  line-height: 30rem;
}

.h4 {
  font-size: $heading-level-4-font-size;
  line-height: 20rem;
}

.h5 {
  opacity: var(--opacity-text-mid);
  font-size: 12rem;
  line-height: 16rem;
  letter-spacing: 0.033em;
  font-weight: $fw-bold;
  text-transform: uppercase;
}
