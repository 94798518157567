@import "../../global";

$date-picker-padding: 10rem;

// Required to ensure that it sits in the right spot
:global {
  #due-date-portal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }

  .react-datepicker {
    background: theme-color(dialog-background);
    color: theme-color(text);
    border: 1rem solid theme-color(border);
    border-radius: $dialog-border-radius;
    box-shadow: $dialog-box-shadow;
    padding: $date-picker-padding;
    font-size: 14rem;
    border-radius: $dialog-border-radius;
    margin-top: 8rem;
    z-index: 999;

    &__day-names {
      display: none;
    }

    &__week {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-gap: 8rem;
      text-align: center;
      margin: 8rem -2rem;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__day {
      height: 26rem;
      width: 26rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      box-sizing: border-box;
      font-weight: $fw-regular;
      cursor: pointer;
      font-variant: tabular-nums;
      letter-spacing: -0.025em;
      padding-right: 0.05em;

      &--outside-month {
        opacity: var(--opacity-text-low);
      }

      &--today {
        font-weight: $fw-medium;
        background: theme-color(text, var(--opacity-ui-low));
      }

      &--selected {
        background: category-color(default);
        color: category-color(text);
        font-weight: $fw-medium;
      }

      &:hover {
        background: theme-color(text);
        color: theme-color(dialog-background);
      }

      &:focus {
        :global(.js--focus-visible) & {
          box-shadow: theme-color(dialog-background) 0 0 0 $focus-ring-width,
            theme-color(focus-ring) 0 0 0 $focus-ring-offset;
        }
      }
    }
  }
}

.button:disabled {
  // We don't want this button to be grayed out when disabled, override base style
  opacity: 1 !important;
}

.buttonDue {
  opacity: var(--opacity-text-mid);
}

.header {
  display: flex;
  align-items: center;
  font-size: 14rem;
  line-height: 20rem;
  margin-bottom: $date-picker-padding;
  flex-wrap: wrap;

  button {
    margin-right: 4rem;

    &:last-of-type {
      margin-right: 0;
    }
  }

  hr {
    margin: $date-picker-padding $date-picker-padding * -1 0;
    width: calc(100% + #{$date-picker-padding * 2});
  }
}

.headerDate {
  flex: 1;
  margin-right: 4rem;
  font-weight: $fw-medium;
  padding-left: 16rem; // Size of one button and its padding
  text-align: center;
}

.footerClear {
  margin-left: auto;
  margin-right: auto;
  margin-top: $date-picker-padding;
}
