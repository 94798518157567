@import "../../../global";

.content {
  white-space: pre-wrap;
  flex: 1;
  font-size: 14rem;
  line-height: 1.4;

  > :last-child {
    margin-bottom: 0;
  }

  a {
    text-decoration: underline;
    position: relative;
    font-weight: $fw-medium;

    &:hover {
      text-decoration: none;
    }

    @include focus-ring($selector: "&::before", $border-radius: 4rem);
  }

  strong {
    font-weight: $fw-bold;
  }

  em {
    // This is faked by browsers, but better to have it supported in case
    // the user has specified it deliberately
    font-style: italic;
  }

  u {
    // This is kept in to honour the desired formatting for users, but could
    // be confused for links (which are also underlined, but also have a medium weight)
    text-decoration: underline;
  }

  // This ensures consistency with the space below lists and paragraphs
  // by ensuring that a <br> that follows immediately (in the case of Gmail that doesn't
  // use paragraphs) doesn't add in an unnecessary extra line.
  ul,
  ol,
  p {
    margin-bottom: 1em;

    + br {
      display: none;
    }
  }

  ul,
  ol {
    padding-left: 1.5em;

    ul,
    ol {
      padding-left: 0.75em; // Reduces the impact of nested lists
    }

    ul {
      li {
        list-style: circle;
      }
    }
  }

  ul {
    li {
      list-style: disc;
    }
  }

  ol {
    li {
      list-style: decimal;
    }
  }
}

.empty {
  margin: auto;
}
