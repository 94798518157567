@import "../../global";

.dropzone {
  position: absolute;
  // Dropzone takes only the middle 50% of the item, the rest is left for the
  // "swap" zone, to rearrange elements
  top: 25%;
  bottom: 25%;
  left: 0;
  right: 0;
  z-index: 0;

  :global(.sortable-ghost) {
    position: absolute;
    // The ghost has to fill the whole item's height
    top: -50%;
    bottom: -50%;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
    border-radius: $item-border-radius;
    // Without this !important the background gets overridden by the parent block
    // which uses currentColor which isn't necessarily the same as the page colour –
    // for example if the action/block is coming from the category sidebar
    background: theme-color(text) !important;
    box-shadow: none !important;
    opacity: var(--opacity-ui-low);

    > * {
      opacity: 0;
    }
  }
}
