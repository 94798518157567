@import "./../../global";

@import "../CreateDialog/CreateDialog.module.scss"; // Uses the styles for the inputs

$extras-height: 327rem;
$extras-margin: 20rem;
$icons-per-row: 9;

.dialog {
  margin-bottom: $extras-height + $extras-margin;
  max-width: (
    ($icons-per-row * 48rem) + (($icons-per-row - 1) * 8rem) +
      ($create-dialog-horizontal-padding * 2) + (1rem * 2)
  ); // 1px for the border
}

.extras {
  background: theme-color(dialog-background);
  border: 1rem solid theme-color(border);
  padding: $create-dialog-vertical-padding $create-dialog-horizontal-padding;
  position: absolute;
  top: calc(100% + #{$extras-margin});
  height: $extras-height;
  left: 0;
  width: 100%;
  border-radius: $dialog-border-radius;
  box-shadow: $dialog-box-shadow;
  color: theme-color(text);
}
