// Animations for the Category Sidebar. The current layout is split between
// Dashboard and Planner containers. Without re-organizing both views, these
// animations are attached to the parent layout.

// Container layout animation for Dashboard/Planner main pages.
@mixin sidebar-container-animate-out() {
  margin-left: $sidebar-max-width * -1;

  @supports (margin-left: clamp(1rem, 2vw, 2rem)) {
    // The clamp needs to be in reverse order as these are negative transforms
    $offset-clamp: clamp(
      $sidebar-max-width * -1,
      $sidebar-width * -1,
      $sidebar-min-width * -1
    );
    margin-left: $offset-clamp;
  }

  transition: margin-left $transition-capture-list-in;
}

@mixin sidebar-container-animate-in() {
  margin-left: 0;
  transition: margin-left $transition-capture-list-out;
}

// Category sidebar animation to slide in/out off screen.
@mixin sidebar-animate-in() {
  visibility: visible;
  transform: none;
  transition: all $transition-capture-list-out;
}

@mixin sidebar-animate-out() {
  visibility: hidden;
  transform: translateX(calc(#{$sidebar-width} * -1));
  transition: all $transition-capture-list-in;
}
