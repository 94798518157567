@use "sass:math";

@import "../../global";

// These are separate so that the number can be exported as an integer (well, a string for conversion)
// via @value (https://github.com/css-modules/css-modules/issues/106#issuecomment-211607047)
// But then also used as a ms value in the transition declaration below
@value stateTransitionDuration: #{math.div($duration-state, 1ms)};

// The ordering here is really important so they are deliberately not collapsed down
// into sharing classes
:global(.slide-appear),
:global(.slide-enter) {
  transition: $duration-state ease-out;
  transition-property: opacity, transform;
  transform: translateY(#{$sidebar-margin});
  opacity: 0;
}

:global(.slide-appear.slide-appear-active),
:global(.slide-enter.slide-enter-active) {
  transform: translateX(0%);
  opacity: 1;
}

:global(.slide-exit) {
  transition: $duration-state ease-in;
  transition-property: opacity, transform;
  transform: translateX(0%);
  opacity: 1;
}

:global(.slide-exit-active) {
  transform: translateY(#{$sidebar-margin});
  opacity: 0;
}
