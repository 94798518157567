@import "../../global";

$sidebar-padding-top: $panel-padding;
$sidebar-padding-bottom: $panel-padding * 2;

.sidebar {
  background: theme-color(detail-background);
  border-radius: $sidebar-border-radius;
  box-shadow: var(--box-shadow) 0 2rem 16rem;
  color: theme-color(text);
  display: flex;
  flex-direction: column;
  margin: $sidebar-margin;
  margin-right: 0;
  padding: 0;
  height: calc(var(--page-height) - #{$sidebar-margin * 2});
  position: relative;
  width: $sidebar-min-width;

  @supports (width: clamp(1rem, 2vw, 2rem)) {
    width: clamp($sidebar-min-width, $sidebar-width, $sidebar-max-width);
  }
}

.header,
.footer {
  padding: $panel-padding;
  border: solid theme-color(border);
  border-width: 1rem 0;

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
}

.header {
  background: theme-color(600);
  border-radius: $sidebar-border-radius $sidebar-border-radius 0 0;
  border-top: 0;
  padding-top: $panel-padding-top - $sidebar-margin;
}

.headerPlain {
  background: none;
}

.footer {
  border-bottom: 0;
  border-radius: 0 0 $sidebar-border-radius $sidebar-border-radius;
}

.scrollerOuter {
  display: flex;
  position: relative;
  overflow: hidden;
  max-height: 100%;
  flex: 1;
  mask: linear-gradient(
    transparent,
    black #{$sidebar-padding-top},
    black calc(100% - #{$sidebar-padding-bottom}),
    transparent
  );

  &:first-child {
    border-top-right-radius: $sidebar-border-radius;
    border-top-left-radius: $sidebar-border-radius;
  }

  &:last-child {
    border-bottom-right-radius: $sidebar-border-radius;
    border-bottom-left-radius: $sidebar-border-radius;
  }
}

.scrollerInner {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 $panel-padding;
  width: 100%;
  scrollbar-gutter: stable; // only supported by new browsers

  > :first-child {
    margin-top: $sidebar-padding-top;
  }

  > :last-child {
    margin-bottom: $sidebar-padding-bottom;
  }
}
