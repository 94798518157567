@import '../../global.scss';

.page {
  grid-template-columns: auto;
  justify-items: stretch;
  align-items: stretch;
}

.helpFloatingButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14rem;
  position: fixed;
  bottom: 30rem;
  right: 30rem;
  z-index: 20;
  background-color: category-color(default);
  color: category-color(text);
  border-radius: 50%;
  width: 100rem;
  height: 100rem;
  gap: 2rem;
  transition: $transition;
  transition-property: color, background-color;

  @include focus-ring(
    $selector: '&::after',
    $border-radius: 50%,
  );

  &:hover {
    background-color: category-color(text);
    color: category-color(default);
  }

  path {
    fill: currentColor;
  }
}
