@import "../../global";

$empty-state-dropzone-default-height: $block-card-height + $card-banner-height +
  $card-margin;
$empty-state-dropzone-height: var(
  --sortable-empty-state-height,
  #{$empty-state-dropzone-default-height}
);

.sortable {
  position: relative;
  z-index: 2;
}

.sortableEnabled {
  [data-id] {
    cursor: grab;
  }

  :global(.sortable-chosen) {
    // Ideally this would persist when the user was dragging, but this is controlled by the browser
    // and applied inconsistently – https://github.com/react-dnd/react-dnd/issues/325
    cursor: grabbing;
  }

  :global(.sortable-ghost) {
    background: currentColor;
    opacity: var(--opacity-ui-lowest);

    > * {
      opacity: 0;
    }
  }
}

// The empty state can't live within .sortable as only the draggable items can
// be present in there, so setting a minimum height equal to the empty state,
// and then positioning the empty state with a negative margin means it
// appears as though it's within the dropzone, but actually it's just below it
.hasEmptyState {
  min-height: $empty-state-dropzone-height;
}

.emptyState {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8rem 16rem;
  height: $empty-state-dropzone-height;
  margin-top: calc(#{$empty-state-dropzone-height} * -1);
  position: relative;
  pointer-events: none;
  z-index: 1;

  &::before {
    // By using currentColor on a pseudo-element we don't have to juggle the
    // colour here and it can be placed in any parent
    background: currentColor;
    border-radius: $card-border-radius;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // This is deliberately faint
    opacity: calc(var(--opacity-ui-lowest) / 2);
  }

  p {
    margin-bottom: 0;
  }

  // .sortable doesn't have a class available when it has had its last item
  // dragged out, but the :empty selector allows us to change the color of the
  // empty state in those instances – the only requirement is that .emptyState
  // be a next sibling of .sortable (it cannot be any sibling because that
  // would break the snoozed screen where there are multiple states)
  .sortable:not(:empty) + & {
    color: transparent;
  }
}
