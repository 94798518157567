@use "sass:math";

@import "../../global";

.header {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 16rem;
  position: relative; // Prevents Safari from misrendering it /shrug
}

.avatar {
  --avatar-size: 64rem;
}

.name {
  margin-top: 2rem; // Vertically aligns the text
  min-height: 64rem;
  display: flex;
  align-items: center;

  > span {
    @include line-clamp(2);

    line-height: 32rem;
    margin: 0;
  }
}

.divider {
  margin-top: $panel-padding * 1.5;
  margin-bottom: $panel-padding * 1.5;
}

.detail {
  font-size: $input-font-size-small;
  line-height: 22rem;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $panel-padding * 0.5;
  padding: 2rem 0;
  justify-content: space-between;

  dt {
    padding-right: $panel-padding;
    margin: 0;
    line-height: inherit;
  }

  dd {
    @include line-clamp(1);

    font-weight: $fw-medium;
    width: auto;
  }
}

.calendarsHeading {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-items: center;

  > span {
    margin: 0;
  }

  > button {
    // Helps align the button with the text in the label
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
}

.buttonsAlignment {
  display: flex;
  align-items: center;
  gap: 10px;
}

.externalService,
.externalCalendar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  > span {
    font-size: 14rem;
    font-weight: $fw-regular;
    line-height: 20rem;
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 5rem;
  }

  button {
    cursor: pointer;
  }
}

.externalService {
  margin-top: $panel-padding;

  > span {
    font-weight: $fw-bold;
  }
}

.externalCalendar {
  // Fixes a weird bug that was causing the entire profile screen to restrict
  // itself at half viewport when clicking on the visibility icons
  position: relative;
  cursor: pointer;
  margin-top: $panel-padding * 0.75;
  // By using color rather than opacity, we preserve the opacity of the focus ring
  // for disabled elements
  color: theme-color(text, var(--opacity-text-low));
  transition: color $transition;

  .visibilityIcon {
    flex: 0 0 auto; // Avoid icon being resized by very long calendar names
    display: block;

    path {
      fill: currentColor;
    }
  }

  &.enabled {
    color: theme-color(text);
  }
}

.externalCalendarInput {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-right: 1rem;
  position: relative;

  input {
    appearance: none;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    width: 16px;
    height: 100%;

    @include focus-ring($selector: "+ .focusRing");
  }
}

.notifications {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
  margin: $panel-padding * 0.5 0;

  @include focus-ring($selector: "> .toggle::after");

  &[disabled] {
    cursor: default;
  }
}
.notificationsLabel {
  margin: 0;
}

$toggle-wrapper-height: 26;
$toggle-wrapper-width: 46;
$toggle-node-size: 22;
$toggle-node-size-focus: 24;

$toggle-wrapper-height-rem: $toggle-wrapper-height * 1rem;
$toggle-node-size-rem: $toggle-node-size * 1rem;

.toggle {
  background: theme-color(text, var(--opacity-ui-low));
  border-radius: 13rem;
  height: $toggle-wrapper-height-rem;
  width: $toggle-wrapper-width * 1rem;
  position: relative;
  transition: $transition;
  transition-property: background, opacity;

  &::before {
    content: "";
    position: absolute;
    left: ($toggle-wrapper-height-rem - $toggle-node-size-rem) * 0.5;
    top: ($toggle-wrapper-height-rem - $toggle-node-size-rem) * 0.5;
    height: $toggle-node-size-rem;
    border-radius: $toggle-node-size-rem * 0.5;
    width: $toggle-node-size-rem;
    background: theme-color(600);
    transition: $transition;
    transition-property: box-shadow, transform;
    transform: translateX(var(--toggle-transform, 0))
      scale(var(--toggle-scale, 1));
    box-shadow: rgba(black, 0) 0 0 4rem;
  }

  [disabled] > & {
    opacity: 0.5;
  }

  :hover:not([disabled]) > & {
    --toggle-scale: #{math.div($toggle-node-size-focus, $toggle-node-size)};

    &::before {
      box-shadow: rgba(black, 0.33) 0 0 4rem;
    }
  }

  &.active {
    --toggle-transform: #{($toggle-wrapper-width - $toggle-wrapper-height) * 1rem};

    background: theme-color(positive);
  }
}

.colorPreference {
  justify-content: space-between;
  margin: 0;
}

.denied {
  font-size: 13rem;
  margin-top: 8rem;
  line-height: 18rem;
  opacity: var(--opacity-text-mid);
}

.buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -4rem;

  > button {
    flex: 1;
    margin: 4rem;
  }
}

.spinner {
  --spinner-size: 5rem;
  margin: 0;
}
