@import "../../../global";

.dialogTabBar {
  // - 1rem  optically balances the border radii
  margin: $dialog-padding $dialog-padding - 1rem 0;
}

.actionsList {
  > :last-child {
    margin-bottom: 0;
  }
}
