@import "../../global";

// DASHBOARD NAV
$icon-size: 26rem;

.dashboardNav {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
}

.centerLinks {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.navLink {
  margin-right: $nav-item-spacing;
  border: 2em solid transparent;
  border-radius: $nav-item-radius;

  &:focus-within {
    border: 2rem solid #{$color-focus-ring};
  }
}

.createActionButton {
  --button-background: #{$colors-light-600};
  --button-color: #{$colors-light-100};
  --button-background-hover: #{$colors-light-600};
  --button-color-hover: #{$colors-light-100};
  --button-icon-size: #{$icon-size};
  --button-min-size: #{$button-size-regular};
  margin-left: $nav-item-spacing;
  padding-top: 9px;
  padding-bottom: 9px;
}

.createActionIcon {
  width: $icon-size;
  height: $icon-size;
  color: $colors-light-600;
}
