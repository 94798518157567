$colors-light-100: #030e24;
$colors-light-200: #858596;
$colors-light-300: #ceced5;
$colors-light-400: #ebebee;
$colors-light-500: #f7f7f9;
$colors-light-600: #fff;

$colors-dark-100: #fff;
// $colors-dark-200: lighten(#5c6175, 5%);
$colors-dark-200: darken(#fff, 20%);
$colors-dark-300: mix(#3f475c, #5c6175);
$colors-dark-400: #3f475c;
$colors-dark-500: #343c4e;
$colors-dark-600: darken(#343c4e, 2%);

$color-accent-light: #6ac6da;
$color-accent: #107a99;
$color-accent-dark: #1a5f80;

$nav-background: #0b0b34;
$body-background: #181e2b;
$body-color: #ffffff;

$color-positive: #5fe85d;
$color-warning: #ff9c07;
// $color-negative: #ff0000;
$color-negative: #DE2B13;

$color-focus-ring: #df2f7f;

$color-yellow-star: #EBAB44;

$colors-light: (
  100: $colors-light-100,
  200: $colors-light-200,
  300: $colors-light-300,
  400: $colors-light-400,
  500: $colors-light-500,
  600: $colors-light-600,
  accent: $color-accent,
  border: $colors-light-400,
  cat-fallback: $colors-light-200,
  positive: $color-positive,
  warning: $color-warning,
  negative: $color-negative,
  focus-ring: $color-focus-ring,
  text: $colors-light-100,
  page-background: $colors-light-600,
  card-background: $colors-light-600,
  dialog-background: $colors-light-600,
  detail-background: $colors-light-500,
  event-background: $colors-light-400,
  reversed-text: white,
  reversed-background: #131823,
  card-bottom-shadow: #eff2f8,
  event-bottom-shadow: darken($colors-light-400, 5%),
  checkbox-ring-border: $colors-light-300,
  checkbox-ring-hover: $colors-light-200,
  yellow-star: $color-yellow-star,
);

$colors-dark: (
  100: $colors-dark-100,
  200: $colors-dark-200,
  300: $colors-dark-300,
  400: $colors-dark-400,
  500: $colors-dark-500,
  600: $colors-dark-600,
  accent: $color-accent-light,
  border: $colors-dark-300,
  cat-fallback: $colors-dark-300,
  positive: $color-positive,
  warning: $color-warning,
  negative: #ff768b,
  focus-ring: $color-focus-ring,
  text: $colors-dark-100,
  page-background: $colors-dark-600,
  card-background: $colors-dark-600,
  dialog-background: $colors-dark-600,
  detail-background: $colors-dark-400,
  event-background: $colors-dark-300,
  reversed-text: white,
  reversed-background: darken($colors-dark-600, 17%),
  card-bottom-shadow: darken($colors-dark-600, 3%),
  event-bottom-shadow: darken($colors-dark-300, 3%),
  checkbox-ring-border: darken($colors-dark-600, 10%),
  checkbox-ring-hover: $colors-dark-100,
  yellow-star: $color-yellow-star,
);

$category-color-shades: "default", light, "dark", "darker";
$category-color-keys: "text", "text-reversed";

$category-colors-0: (
  default: #757489,
);

$category-colors-1: (
  default: #e25780,
);

$category-colors-2: (
  default: #f1672a,
);

$category-colors-3: (
  default: #fa8800,
);

$category-colors-4: (
  default: #1fb849,
);

$category-colors-5: (
  default: #43bde2,
);

$category-colors-6: (
  default: #7e68ff,
  // The programatic color creates a very blue-y darker variant, this overrides
  // it to normalise it
  darker: #453891,
);

$category-colors-7: (
  default: #bd6bff,
);

$category-colors-8: (
  default: #e455c1,
);

$category-color-sets: (
  0: $category-colors-0,
  1: $category-colors-1,
  2: $category-colors-2,
  3: $category-colors-3,
  4: $category-colors-4,
  5: $category-colors-5,
  6: $category-colors-6,
  7: $category-colors-7,
  8: $category-colors-8,
);
