@import "../../global";

.buttonSnoozeLabel {
    transition: opacity $transition;
    font-weight: $fw-medium;
    color: currentColor;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: center;
  
    span {
        text-transform: none;
    }
  }

  .focusRing {
    position: relative;
  
    @include focus-ring(
      $selector: "&::before",
      $border-radius: $button-square-border-radius + 4rem,
    );
  }
  