@import "../../global";

.ring {
  --ring-border: #{theme-color(checkbox-ring-border)};
  --ring-border-hover: #{theme-color(checkbox-ring-hover)};
  --ring-shadow: #{theme-color(card-bottom-shadow)};
  --ring-background: #{theme-color(card-background)};
  --ring-background-active: #{category-color(default)};
  --ring-color-active: #{category-color(text)};

  box-shadow: inset var(--ring-shadow) 0 3rem 0;
  border-radius: 50%;
  display: flex;
  z-index: 3;
  background: var(--ring-background);
  align-items: center;
  justify-content: center;
  height: $action-item-completion-ring-width;
  width: $action-item-completion-ring-width;
  position: relative;
  transition: $transition;
  transition-property: background, box-shadow, color;

  &::before,
  &::after {
    box-sizing: border-box;
    content: "";
    border-radius: inherit;
    position: absolute;
    // Accounts for the border
    top: -1rem;
    left: -1rem;
    bottom: -1rem;
    right: -1rem;
  }

  &::before {
    border: 1rem solid var(--ring-border);
    z-index: 0;
    transition: border-color $transition;
  }

  &::after {
    background: var(--ring-background-active);
    opacity: 0;
    transition: opacity $transition;
  }

  svg {
    height: 10rem;
    width: 10rem;
    position: relative;
    z-index: 1;
    opacity: 0;
    transition: opacity $transition;
  }

  path {
    fill: var(--ring-border-hover);
    transition: color $transition;
  }

  .wrapper:not([disabled]):hover > & {
    box-shadow: inset transparent 0 3rem 0;

    &::before {
      border-color: var(--ring-border-hover);
    }

    svg {
      opacity: var(--opacity-text-low);
    }
  }
}

.completed {
  &::after,
  svg {
    opacity: 1;
  }

  path {
    fill: var(--ring-color-active);
  }

  .wrapper:not([disabled]):hover > & {
    svg {
      opacity: var(--opacity-text-low);
    }
  }
}

.categoryColor {
  --ring-border: #{category-color(darker, 0.5)};
  --ring-border-hover: #{category-color(darker)};
  --ring-shadow: #{category-color(default, 0.8)};
  --ring-background: #{category-color(light, 0.5)};
  --ring-background-active: #{category-color(text)};
  --ring-color-active: #{category-color(text-reversed)};
}
