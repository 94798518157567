@import "../../global";

.sidebar {
  grid-area: 1 / 2;
  display: flex;
  opacity: 1;
  z-index: 2;
  @include sidebar-animate-in;

  &.hidden {
    opacity: 0;
    @include sidebar-animate-out;
  }
}
