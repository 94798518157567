@import "../../global";

.button {
  margin: 16rem auto 4rem;
}

.hasError {
  // Wins the specificity war over .Button[disabled]
  &[disabled] {
    --button-background: #{theme-color(negative)};
    --button-color: #{theme-color(600)};

    // Overrides the transparency set for disabled buttons to show
    // the full negative background color
    opacity: 1 !important;
  }

  svg {
    height: 24rem;
    width: 24rem;
  }
}
