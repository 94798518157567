@import "../../global";

@import "../CreateDialog/CreateDialog.module.scss";

$email-body-heading-height: 36rem;

.form {
  flex-direction: column;
  align-items: flex-start;
}

.autocomplete {
  font-size: $create-dialog-input-font-size;
  line-height: $create-dialog-input-line-height;
  padding: $create-dialog-input-padding 0;
  padding-right: 50rem; // Accounts for the character counter
  margin-bottom: 12rem;
  margin-top: -6rem; // Reduces the default spacing from the label before for a tighter arrangement
  overflow: hidden;
  width: 100%;
}

.characterCount {
  position: absolute;
  right: $create-dialog-horizontal-padding;
  top: $create-dialog-vertical-padding;
  opacity: var(--opacity-text-low);
  font-size: 13rem;
  font-weight: $fw-bold;
  font-variant-numeric: tabular-nums;
}

.parentSelect {
  margin-bottom: -4rem; // Optically realigns with the padding for the dialog
}

.emailBody {
  background: inherit;
  border: inherit;
  border-radius: inherit;
  box-shadow: inherit;
  position: absolute;
  top: calc(100% + #{30rem});
  max-height: calc(50vh - #{100rem * 0.5 + 30rem + 30rem});
  left: 0;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;

  p {
    -webkit-mask: linear-gradient(
      rgba(black, 0) 1rem + $email-body-heading-height,
      rgba(black, 0.33) #{$create-dialog-vertical-padding * 0.25 +
        $email-body-heading-height},
      rgba(black, 0.66) #{$create-dialog-vertical-padding * 0.5 +
        $email-body-heading-height},
      black #{$create-dialog-vertical-padding + $email-body-heading-height},
      black calc(100% - #{$create-dialog-vertical-padding}),
      rgba(black, 0.66) calc(100% - #{$create-dialog-vertical-padding * 0.5}),
      rgba(black, 0.33) calc(100% - #{$create-dialog-vertical-padding * 0.25}),
      rgba(black, 0) calc(100% - 1rem)
    );

    white-space: pre-line; // Ensures that the line breaks from the original email are respected
    padding: $create-dialog-vertical-padding + $email-body-heading-height
      $create-dialog-horizontal-padding $create-dialog-vertical-padding;
    font-size: 15rem;
    line-height: 24rem;
    overflow: auto;
    flex: 1;
  }
}

.emailBodyTitle {
  padding: 0 $create-dialog-horizontal-padding;
  display: flex;
  align-items: flex-end;
  font-size: 13rem;
  height: $email-body-heading-height;
  font-weight: $fw-medium;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}

.actionFooter {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.scheduleWrapper {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  .configActionButton {
    margin-right: 12rem;

    svg {
      color: theme-color(200);
    }

    .favoriteIcon {
      svg {
        color: theme-color(yellow-star);
      }
    }

    .activeIcon {
      svg {
        color: theme-color(100);
      }
    }
  }

.activeSelector {
  svg {
      color: theme-color(100);
  }
}

  button {
    position: relative;
  
    @include focus-ring(
      $selector: "&::before",
      $border-radius: $button-square-border-radius + 4rem,
    );
  }
}

.scheduleLabel {
  margin-bottom: 2rem; // Optically balances the label
}

.scheduleTime {
  margin: 0 12rem;
}

.actionsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  .cancelButton {
    margin-right: 8rem;
    --button-border: transparent;
  }
}

.buttonWithSelect {
  padding: 0;
  align-items: stretch;

  select {
    // These reverts are required to ensure that Windows machines don't show it as white text
    // when .cardWithColor is present, it also resets the font-weight
    color: revert;
    font-weight: revert;
    cursor: pointer;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @include focus-ring(
      $selector: "+ .focusRing",
      $border-radius: $button-square-border-radius + 4rem
    );
  }

  svg {
    margin: 1rem 6rem 0 0;
    width: $select-arrow-width;
    height: $select-arrow-height;
  }
}

.buttonDueDateLabel {
  transition: opacity $transition;
  font-weight: $fw-medium;
  color: currentColor;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 0 2rem;

  span {
    padding-top: 1rem;
    padding-left: 2rem;

    text-transform: none;
  }
}

.timeWrapper {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin: 8rem 0;

  span {
    margin: 0;
  }
}

.timeSelect {
  padding-left: 5rem;
}

