@import "../../global";

$avatar-size: var(--avatar-size, 40rem);

.avatar {
  background: theme-color(200);
  color: theme-color(600);
  font-weight: $fw-bold;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: $avatar-size;
  width: $avatar-size;
  font-size: calc(#{$avatar-size} * 0.4);
  position: relative;
  text-transform: uppercase;

  path {
    fill: currentColor;
  }
}

.image {
  border-radius: inherit;
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.icon {
  height: 60%;
  width: 60%;
}
