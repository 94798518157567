@import "../../../global";

@import "../DetailPanel.module.scss";

.main {
  width: calc(100% - #{$sidebar-margin * 2});
  height: calc(100% - #{$sidebar-margin * 2});
  margin: $sidebar-margin;
  border-radius: $sidebar-border-radius;
  position: relative;
  overflow: hidden;
}

.header {
  padding-top: 0;
}

.avatar {
  --avatar-size: 95rem;

  margin: calc(var(--avatar-size) / -2) auto 0;
}

.title {
  margin-top: 10rem;
  margin-bottom: 16rem;
  text-align: center;
}

.description {
  margin-top: 16rem;
  margin-bottom: 0;
  text-align: center;
}

.counters {
  justify-content: center;
}

.subheading {
  margin-top: 24rem;
}

.emptyState {
  margin-top: 20rem;
}
