@import "../../global";

$button-height: 32;
$button-height-rem: $button-height * 1rem;
$button-height-var: var(--button-min-size, #{$button-height});

$button-height-hover-increase: 4;

.button {
  box-sizing: border-box;
  align-items: center;
  color: var(--button-color);
  // This is deliberately oversized to ensure that other buttons defined elsewhere (e.g. the 50x50 expand
  // button on the dashboard) have completely rounded corners too
  border-radius: 100rem;
  display: flex;
  cursor: pointer;
  font-size: 11rem;
  line-height: 12rem;
  font-weight: $fw-bold;
  justify-content: center;
  transition: $transition;
  transition-property: color, transform, opacity;
  text-transform: uppercase;
  position: relative;
  min-height: calc(#{$button-height-var} * 1rem);
  min-width: calc(#{$button-height-var} * 1rem);
  padding: 2rem
    calc(
      var(--button-min-size, #{$button-height}) * var(--button-padding, 0.5rem)
    );

  &::after {
    box-sizing: border-box;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background: var(--button-background);
    border: 1rem solid var(--button-border);
    transition: $transition;
    transition-property: background, border, opacity, transform;
    opacity: var(--button-after-opacity);
  }

  &:hover:not([disabled]):not(.loading),
  &:active:not([disabled]):not(.loading) {
    color: var(--button-color-hover);

    &::after {
      background-color: var(--button-background-hover);
      border-color: var(--button-border-hover);
      opacity: var(--button-after-opacity-hover);
      transform: scale(var(--button-after-scale-hover));
    }
  }

  &:active:not([disabled]):not(.loading) {
    // This scale transforms the entire button back down to the original size on :active
    transform: scale(calc(#{$button-height-var} / (#{$button-height-var} + 4)));
  }

  @include focus-ring($selector: "&::before");

  &[disabled]:not(.loading) {
    cursor: default;
    opacity: $button-disabled-opacity;

    > * {
      pointer-events: none;
    }
  }

  &.loading {
    cursor: progress;
  }

  svg {
    height: var(
      --button-icon-size,
      calc(var(--button-min-size, #{$button-height}) * 0.5rem)
    );
    width: auto;
    margin-bottom: -0.075em;
  }

  path {
    fill: currentColor;
  }

  &.iconOnly {
    padding: 0;

    svg {
      margin-right: 0;
      margin-left: 0;
    }
  }
}

.outline {
  --button-border: currentColor;
  --button-after-opacity: var(--opacity-ui-mid);
  --button-color: currentColor;

  --button-border-hover: currentColor;
  --button-background-hover: currentColor;
  --button-after-opacity-hover: var(--opacity-ui-mid);
}

.category {
  --button-background: #{category-color(default)};
  --button-color: #{category-color(text)};
  --button-border: transparent;

  --button-background-hover: #{category-color(dark)};
  --button-color-hover: #{category-color(text)};
  --button-border-hover: transparent;
}

.fill {
  --button-background: #{$color-accent};
  --button-color: white;
  --button-border: transparent;

  --button-background-hover: #{$color-accent-dark};
  --button-color-hover: white;
  --button-border-hover: transparent;
}

// For error coloured buttons like delete, cancel, etc
.negative {
  --button-color: #{theme-color(negative)};
  --button-color-hover: #{theme-color(negative)};

  &.fill {
    --button-background: #{theme-color(negative)};
    --button-border: #{theme-color(negative)};
  }
}

.xsmall {
  --button-min-size: #{$button-size-xsmall};
  --button-icon-size: 10rem;
}

.small {
  --button-min-size: #{$button-size-small};
  --button-icon-size: 14rem;
}

.large {
  --button-min-size: #{$button-size-large};
  --button-icon-size: 24rem;

  font-size: 16rem;
  line-height: 18rem;
}

.children {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  transition: opacity $transition;
  z-index: 2;
  padding-bottom: 0.075em;
  max-width: 100%;

  .loading & {
    opacity: 0;
  }
}

.spinner {
  --spinner-size: 6rem;

  z-index: 3;
}

.square {
  --button-padding: 0.25rem;

  border-radius: $button-square-border-radius;

  @include focus-ring(
    $selector: "&::before",
    $border-radius: $button-square-border-radius + $focus-ring-offset
  );
}

.block {
  max-width: $button-block-width;
  width: 100%;
}

.iconLeft {
  svg {
    margin-right: calc(
      var(--button-padding, 0.5rem) * var(--button-min-size, #{$button-height}) /
        2
    );
    // Recesses the icon into the padding slightly
    margin-left: calc(var(--button-padding, 0.5rem) * -1);
  }
}

.iconRight {
  svg {
    margin-left: calc(
      var(--button-padding, 0.5rem) * var(--button-min-size, #{$button-height}) /
        2
    );
    // Recesses the icon into the padding slightly
    margin-right: calc(var(--button-padding, 0.5rem) * -1);
  }
}
