@import "../../global";

.base {
  cursor: pointer;
  position: relative;
  text-align: inherit;

  @include focus-ring($selector: "&::after", $border-radius: 4rem);
}

.link {
  font-weight: $fw-medium;
  color: theme-color(accent);
  margin: 0;
  text-decoration: underline;
  width: auto;

  &:hover {
    color: currentColor;
  }
}

.placeholder {
  font-weight: $fw-regular;
  opacity: var(--opacity-text-low);
  transition: opacity $transition;
  width: 100%;

  &:hover,
  &:focus {
    // Focus opacity is needed to prevent the focus ring from being semi-transparent
    opacity: 1;
  }
}
