@import "../../global";

.profilePage {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.sections {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto 1fr;
  grid-gap: 0 $panel-padding;
}

.title {
  margin: 0;
  font-size: 48rem;
}

.topLine {
  margin-top: 24rem;
}

.subtitle {
  margin-top: 4rem;
  margin-bottom: 12rem;
}

.sectionList {
  li {
    display: flex;
    font-size: 13rem;
    line-height: 16rem;

    + li {
      margin-top: 16rem;
    }
  }

  svg {
    flex-shrink: 0;
    margin-right: 4rem;
  }

  path {
    fill: currentColor;
  }
}

.count {
  display: inline-block;
  min-width: 2ch;
  font-weight: $fw-medium;
  font-variant-numeric: tabular-nums;
  vertical-align: top;
  text-align: right;
  letter-spacing: -0.05em; // Brings the tabular numbers slightly closer together
}

.goalHeading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  a {
    min-width: 75rem;
  }
}

.goals {
  grid-auto-flow: row;
  grid-gap: 0 ($panel-padding * 4); // Space the goals more apart compared to above grid.
  margin-top: 130rem;

  @media screen and (max-width: 78em) {
    grid-template-columns: repeat(auto-fit, minmax(400rem, 1fr));
  }
}

.goalBlock {
  min-width: 400rem;
  margin-bottom: 130rem;

  .placeholder {
    cursor: pointer;
    opacity: 0.7;
  }

  p {
    word-wrap: break-word;
    white-space: pre-line;
  }
}

.profileFooter {
  display: flex;
  flex: 1;
  margin-top: auto;
}

.footerLinks {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  padding-right: $panel-padding-page;
  margin-top: auto;

  .link {
    margin-left: $panel-padding;
  }
}
