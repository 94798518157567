@import "../../../global";

.dialogSection {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;

  &[hidden] {
    display: none;
  }
}
