@use "sass:math";

@import "../../global";

$tab-bar-height: 32rem;
$tab-bar-border: 2rem;

.tabBar {
  display: flex;
  font-size: 14rem;
  margin: 0 -2rem; // Optically counteracts the rounded corners
  font-weight: $fw-medium;
  height: $tab-bar-height;
  flex-shrink: 0;
  position: relative;
  z-index: 5; // Puts the tab bar above most things to ensure that the slider at the bottom isn't obscured
}

$tab-gutter: 4rem;
$tab-width-percent: (100% / var(--tab-count));
$tab-width-gutter: "(#{$tab-gutter} / var(--tab-count) * (var(--tab-count) - 1))";
$tab-width-calc: calc(#{$tab-width-percent} - #{unquote($tab-width-gutter)});

.list {
  font-size: 12rem;
  border-radius: $tab-bar-height * 0.5;
  display: grid;
  grid-template-columns: repeat(var(--tab-count), $tab-width-calc);
  grid-gap: 0 $tab-gutter;
  width: 100%;
  padding: $tab-bar-border;
  position: relative;

  &::before {
    content: "";
    background: rgba(var(--category-dark, var(--color-cat-fallback)), 1);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: inherit;
    pointer-events: none;
  }
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  border-radius: $tab-bar-height * 0.5;
  height: $tab-bar-height - ($tab-bar-border * 2);
  line-height: $tab-bar-height - ($tab-bar-border * 2);
  box-sizing: border-box;
  cursor: pointer;
  width: 100%;
  position: relative;

  &::before,
  &::after {
    content: "";
    position: absolute;
    background: currentColor;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    transition: opacity $transition;
    z-index: 1;
  }

  &::after {
    background: theme-color(600);

    .categoryColor & {
      background: category-color(default);
    }
  }

  &:hover {
    &::before {
      opacity: var(--opacity-ui-low);
    }
  }

  &.active {
    cursor: default;

    &::before {
      opacity: 0;
    }

    &::after {
      opacity: 1;
    }
  }
}

.labelInner {
  display: flex;
  flex-wrap: wrap;
  height: 16rem;
  line-height: 16rem;
  margin: auto 0;
  min-width: 16rem;
  overflow: hidden;
  align-content: flex-end;
  justify-content: center;
  position: relative;
  padding: 0 6rem;
  transition: color $transition;
  color: currentColor;
  z-index: 2;

  .tab:hover & {
    opacity: 1;
  }

  .active & {
    // color: rgba(var(--category-default, var(--color-cat-fallback)), 1);
    color: theme-color(100);
    opacity: 1;

    .categoryColor & {
      color: category-color(text);
    }
  }

  > span {
    @include line-clamp(1);

    width: auto;
  }

  svg {
    display: inline-block;
    vertical-align: top;
    width: 16rem;
    height: 16rem;
    flex-shrink: 0;

    // If it is the only element (no label) then it has a zero footprint
    &:not(:last-child) {
      margin-right: 4rem;
    }

    path {
      fill: currentColor;
    }
  }
}

.input {
  // <label> is used for the click interaction, this is invisible over the top
  // to support :moz-focus-ring
  appearance: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  &:focus {
    color: pink !important;
  }

  @include focus-ring(
    $selector: "~ .focusRing",
    $border-radius: $tab-bar-height * 0.5 + 4rem
  );
}
