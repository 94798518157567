@use "sass:list";

@import "../../global";

$spinner-size: var(--spinner-size, 10rem);
$spinner-gap: list.slash($spinner-size, 2);
$spinner-duration: 1400ms;
$spinner-delay: 160ms;

.spinner {
  --spinner-size: 10rem;

  opacity: var(--opacity-ui-mid);
  display: block;
  margin: 16rem auto;
  width: calc((#{$spinner-size} * 3) + (#{$spinner-size} / 2 * 2));
}

.bounce {
  width: #{$spinner-size};
  height: #{$spinner-size};
  margin-left: calc(#{$spinner-size} / 2);
  background-color: currentColor;

  border-radius: 100%;
  display: inline-block;
  animation: bounce $spinner-duration infinite ease-in-out both;

  &:nth-child(1) {
    animation-delay: $spinner-delay * -2;
    margin-left: 0;
  }

  &:nth-child(2) {
    animation-delay: $spinner-delay * -1;
  }
}

@keyframes bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

.absolute {
  left: 50%;
  position: absolute;
  margin: 0;
  top: 50%;
  transform: translate(-50%, -50%);
}

.opaque {
  opacity: 1;
}
