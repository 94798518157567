@use "sass:math";

// Names are obfuscated to preserve EULA from webfont providers
$font-heading: "RRI Headings"; // Tungsten
$font-body: "RRI Body"; // Circular

$ff-heading: $font-heading, sans-serif;
$ff-body: $font-body, sans-serif;

$fw-regular: 400;
$fw-medium: 600;
$fw-bold: 800;
$fw-heading: 600; // Only one weight of Tungsten is used for all headings

$focus-ring-width: 2rem;
$focus-ring-offset: $focus-ring-width * -2;

$duration: 150ms;
$transition: $duration linear;

$duration-capture-list-in: 600ms;
$duration-capture-list-out: 300ms;
$transition-capture-list-in: $duration-capture-list-in
  cubic-bezier(0.65, 0.26, 0, 1.1);
$transition-capture-list-out: $duration-capture-list-out ease-in;

$duration-state: 250ms;

$nav-height: 80rem;

$nav-item-active-alpha: 0.14;
$nav-item-radius: 8rem;
$nav-item-spacing: 10rem;
$nav-item-font-size: 11rem;

$panel-padding: 16rem;
$panel-padding-page: 24rem;
$panel-padding-top: 50rem;

$sidebar-min-width: 300rem;
// Ensures that the desired size of 300px is met at 1280px wide
$sidebar-width: math.div(math.div($sidebar-min-width, 1rem), 1280) * 100vw;
$sidebar-max-width: 400rem;
$sidebar-margin: 20rem;
$sidebar-collapsed-width: 64rem;
$sidebar-border-radius: 20rem;
$sidebar-heading-padding: 16rem;
$sidebar-collapse-radius: 10rem;

$inset-box-shadow: inset var(--inset-box-shadow) 0 -4rem 0;

$item-height: 48rem;
$item-padding: 8rem;
$item-border-radius: 10rem;
$item-margin: 6rem;
$item-line-height: 18rem; // Tighter than the Heading it uses by design
$item-icon-size: 32rem;
$item-active-indicator-size: 6rem;

$card-padding: 12rem;
$card-top-padding: 8rem;
$card-bottom-padding: 11rem;
$card-buttons-margin: 6rem;
$card-border-radius: 10rem;
$card-title-font-size: 14rem;
$card-font-size: 13rem;
$card-line-height: 16rem;
$card-margin: 6rem;
$card-banner-height: 21rem;

$action-card-height: 48rem;
$action-item-completion-ring-width: 18rem;
$action-item-completion-button-width: $action-item-completion-ring-width +
  ($card-padding * 2);

$block-card-height: 80rem;

$contextual-menu-z-index: 9900;

$dialog-z-index: 998;
$dialog-border-radius: 10rem;
$dialog-border-width: 1rem;
$dialog-padding: 12rem;
$dialog-box-shadow: var(--box-shadow) 0 1rem 8rem;

$input-font-size-small: 14rem;
$input-font-size-regular: 18rem;
$input-placeholder-opacity: var(--opacity-text-mid);
$input-placeholder-font-weight: $fw-regular;

$select-arrow-height: 8rem;
$select-arrow-width: 5rem;

$heading-level-0-line-height: math.div(54, 64);
$heading-level-0-large-font-size-breakpoint: 960;
$heading-level-3-font-size: 22rem;
$heading-level-4-font-size: 16rem;

$button-size-xsmall: 18;
$button-size-small: 24;
$button-size-regular: 32;
$button-size-large: 50;
$button-disabled-opacity: var(--opacity-text-low);

$button-block-width: 200rem;
$button-square-border-radius: 6rem;

$floating-button-size: $button-size-large;
$floating-button-size-rem: $floating-button-size * 1rem;
$floating-button-offset: $sidebar-margin * 2;

$character-counter-size: 32rem;
