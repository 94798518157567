@import "../../global";

$dialog-body-background: theme-color(detail-background);

@include light-theme {
  .lightbox {
    background: rgba($colors-light-100, 0.2);
  }
}

@include dark-theme {
  .lightbox {
    background: rgba(#07080b, 0.6);
  }
}

.lightbox {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: $dialog-z-index;

  &[hidden] {
    display: none;
  }

  &.bottomRight {
    justify-content: flex-end;
    align-items: flex-end;
  }
}

// Beats the [data-color-preference] specificity added by the light-/dark-theme
[data-color-preference] .noLightbox {
  background: transparent !important;
  pointer-events: none;
}

// Beats the [data-color-preference] specificity added by the light-/dark-theme
[data-color-preference] .bottomRight {
  background: transparent !important;
  pointer-events: none;
}

.dialog {
  margin: 0;
  max-width: 400rem;
  display: flex;
  flex-direction: column;
  background: $dialog-body-background;
  border: $dialog-border-width solid theme-color(border);
  border-radius: $dialog-border-radius;
  pointer-events: auto;
  box-shadow: $dialog-box-shadow;
  color: theme-color(text);
  font-size: 14rem;
  line-height: 20rem;
  width: 95%;
  max-height: 95%;

  p + p {
    margin-top: 10rem;
  }
}

.header,
.footer {
  background: theme-color(dialog-background);
  border: $dialog-border-width solid theme-color(border);
  border-radius: $dialog-border-radius;
  margin: $dialog-border-width * -1;
  padding: $dialog-padding;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.header {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  flex-wrap: wrap;
  margin-bottom: 0;
  min-height: ($button-size-small * $dialog-border-width) +
    ($dialog-padding * 2);
  position: relative;
}

.headerReversed {
  @include category-opacity-var-override;

  background: theme-color(reversed-background);
  border-bottom: 0;
  border-color: theme-color(reversed-background);
  color: theme-color(reversed-text);
  padding-bottom: $dialog-padding;
}

.headerTitle {
  @include line-clamp(2);

  opacity: 1;
  text-align: center;
  line-height: 14rem;
  max-width: 100%;
  position: relative;
  margin: -8rem 0;
  flex: 1;

  .headerHasButtons & {
    text-align: left;
  }
}

.headerButtons {
  position: relative;
  margin-left: $dialog-padding * 0.5;
  display: flex;

  > button,
  > a {
    margin-left: $dialog-padding * 0.5;
  }
}

$dialog-header-max-lines-default: 3;
$dialog-header-collapsable-padding-bottom: 12rem;
$dialog-header-collapsable-padding-top: 8rem;
$dialog-header-collapsable-line-height: 17rem;

.headerCollapsableOuter {
  mask: linear-gradient(
      to bottom,
      black calc(100% - #{$dialog-header-collapsable-padding-top}),
      transparent
    )
    center right;
  margin-bottom: $dialog-padding * -1;
  font-size: 13rem;
  line-height: $dialog-header-collapsable-line-height;
  grid-column: 1 / span 3;
  font-weight: $fw-medium;
  // Calculating a max-height from the padding of .headerCollapsableInner and the maximum
  // lines before truncation (set by the CSS custom prop --dialog-header-max-lines) allows
  // for a CSS animation without any JS needed to calc the height of the children.
  max-height: calc(
    #{$dialog-header-collapsable-padding-top +
      $dialog-header-collapsable-padding-bottom} + (var(
            --dialog-header-max-lines,
            #{$dialog-header-max-lines-default}
          ) * #{$dialog-header-collapsable-line-height})
  );
  transition: opacity 100ms 200ms, max-height 300ms;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: flex-start;
  will-change: height;
  width: 100%;

  .headerCollapsed & {
    max-height: 0;
    opacity: 0;
    transition: opacity 100ms, max-height 300ms;
  }
}

.headerCollapsableInner {
  padding-bottom: $dialog-header-collapsable-padding-bottom;
  padding-top: $dialog-header-collapsable-padding-top;
  width: 100%;
  overflow: hidden;
}

.headerCollapsableContent {
  @include line-clamp(
    var(--dialog-header-max-lines, #{$dialog-header-max-lines-default})
  );
}

.body {
  padding: $dialog-padding;
  background: $dialog-body-background;
  overflow-y: auto;

  // If there is no footer, the body needs to be rounded to avoid the background spilling over
  // the edges
  &:last-child {
    border-radius: $dialog-border-radius;
  }
}

.footer {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  justify-content: center;
  margin-top: auto;

  button {
    &:not(:last-child) {
      margin-right: $dialog-padding;
    }
  }
}

.noContent {
  border-top: 0;
}
